const fsc = {

    isFullscreen() {
        return document.fullscreenElement != null;
    },

    openFullscreen() {
        window.dispatchEvent(new CustomEvent("APP_FULLSCREEN", { 'detail': true }), true);
        if (this.isFullscreen()) {
            return;
        }
        var elem = document.documentElement;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
            elem.msRequestFullscreen();
        }
    },

    closeFullscreen() {
        window.dispatchEvent(new CustomEvent("APP_FULLSCREEN", { 'detail': false }), true);
        if (!this.isFullscreen()) {
            return;
        }
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    }

}

export default fsc; 