import React from 'react'
import BaseComponent from '../../../common/base';
import { Button, Card } from 'react-bootstrap';
import QuestionnairePartialCard from '../../partials/card';
import fsc from '../../../common/fullscreen';

export class StepThankYouPage extends BaseComponent {

    componentDidMount() {
        super.componentDidMount();
        fsc.closeFullscreen();
    }

    render() {
        return (
            <>
                <QuestionnairePartialCard
                    title={this.t(`questionnaire.thanks.thankYou.title`)}
                    body={
                        <>
                            <Card.Text>
                                {this.t(`questionnaire.thanks.thankYou.text.01.${this.props.gender}`)}
                            </Card.Text>

                            {
                                /*
                                    <Card.Text>
                                        {this.t(`questionnaire.thanks.thankYou.text.02.${this.props.gender}`)}
                                    </Card.Text>

                                    <Button type="submit" variant="primary" className="mt-2 mb-3">
                                        {this.t(`questionnaire.common.downloadReport`)}
                                    </Button>
                                */
                            }

                            <Card.Text>
                                {this.t(`questionnaire.thanks.thankYou.text.03.01`)}
                                <br />
                                {this.t(`questionnaire.thanks.thankYou.text.03.02`)}
                            </Card.Text>
                        </>
                    }
                    footer={
                        <span>
                            {
                                this.props.isTablet && (
                                    <Button
                                        variant="success"
                                        onClick={(e) => {
                                            let baseUrl = this.props.isTablet ? `${process.env.REACT_APP_URL}/${this.props.region}/${this.props.language}/finished` : `${process.env.REACT_APP_URL}/${this.props.region}/${this.props.language}/`;
                                            window.location.replace(baseUrl);
                                        }}
                                    >
                                        FINISH STUDY
                                    </Button>
                                )
                            }
                        </span>
                    }

                />
            </>
        )
    }

}

export default StepThankYouPage;