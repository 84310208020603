import React from 'react';
import { Navbar } from 'react-bootstrap';
import BaseComponent from '../../common/base';

export class Footer extends BaseComponent {

    render() {
        return (
            <Navbar className="p-0 pt-2 pb-2 footer d-none">
                <Navbar.Collapse className="justify-content-center">
                    <Navbar.Text>
                        {this.t('landing.section.footer.text')}
                        {` `}
                        <a href={`${process.env.PUBLIC_URL}/us/en/${this.props.link || ''}`}>en</a>
                        {` / `}
                        <a href={`${process.env.PUBLIC_URL}/eu/pl/${this.props.link || ''}`}>pl</a>
                        {` / `}
                        <a href={`${process.env.PUBLIC_URL}/eu/uk/${this.props.link || ''}`}>uk</a>
                        {` / `}
                        <a href={`${process.env.PUBLIC_URL}/eu/ru/${this.props.link || ''}`}>ru</a>                        
                    </Navbar.Text>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default Footer
