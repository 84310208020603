import React from 'react'
import BaseComponent from '../../../../common/base';
import { Form, Button } from 'react-bootstrap';
import QuestionnairePartialCard from '../../../partials/card';

export class StepMemoryCheckFirst extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = Object.assign(
            this.state,
            {
                first: "",
                second: "",
                third: "",
                fourth: "",
                fifth: ""
            }
        );
    }

    onSubmit(e) {
        this.props.dbSet('memory.first.reactionTime', this.firstReactionTime());
        this.props.dbSet('memory.first.responseTime', this.sinceMount());
        this.props.nextStep();
    }

    onRedo(e) {

        this.measureReaction();

        const currentReactionTime = this.props.dbGet('memory.first.reactionTime', null);
        const currentResponseTime = this.props.dbGet('memory.first.responseTime', null);
        const currentErrorCount = this.props.dbGet('memory.first.errorCount', 0);

        if (currentReactionTime === null) {
            this.props.dbSet('memory.first.reactionTime', this.firstReactionTime());
        }

        if (currentResponseTime === null) {
            this.props.dbSet('memory.first.responseTime', this.sinceMount());
        } else {
            this.props.dbSet('memory.first.responseTime', currentResponseTime + this.sinceMount());
        }

        this.props.dbSet('memory.first.errorCount', currentErrorCount + 1);

        if (currentErrorCount >= 2) {
            this.props.nextStep();
        } else {
            this.props.rewindSteps(e, -5)
        }
    }

    render() {

        const { first, second, third, fourth, fifth } = this.state;
        const items = [
            { ident: "first", correct: this.normalizeWord(first) === this.normalizeWord(this.t('questionnaire.memory.word.face')) },
            { ident: "second", correct: this.normalizeWord(second) === this.normalizeWord(this.t('questionnaire.memory.word.velvet')) },
            { ident: "third", correct: this.normalizeWord(third) === this.normalizeWord(this.t('questionnaire.memory.word.church')) },
            { ident: "fourth", correct: this.normalizeWord(fourth) === this.normalizeWord(this.t('questionnaire.memory.word.daisy')) },
            { ident: "fifth", correct: this.normalizeWord(fifth) === this.normalizeWord(this.t('questionnaire.memory.word.red')) },
        ];
        let allCorrect = true;

        return (
            <>
                <QuestionnairePartialCard
                    onSubmit={(e) => this.onSubmit(e)}
                    title={this.t('questionnaire.memory.check.first.title')}
                    body={
                        <>
                            {items.map((itm) => {
                                if (!itm.correct) {
                                    allCorrect = false;
                                }
                                return (
                                    <Form.Group key={itm.ident}>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder={this.t(`questionnaire.memory.check.placeholder.${itm.ident}`)}
                                            isValid={itm.correct}
                                            value={this.state[itm.ident]}
                                            onChange={
                                                (e) => {
                                                    this.measureReaction();
                                                    this.setState({ [itm.ident]: e.target.value })
                                                }
                                            }
                                        />
                                    </Form.Group>
                                )
                            })}
                        </>
                    }
                    footer={
                        <>
                            <Button
                                type="submit"
                                variant="primary"
                                disabled={allCorrect === false}
                            >
                                {this.t('questionnaire.common.saveAndNextStep')}
                            </Button>

                            {
                                allCorrect === false && (
                                    <Button
                                        variant="light"
                                        className="float-right"
                                        onClick={(e) => this.onRedo()}
                                    >
                                        {this.t('questionnaire.memory.check.first.redo')}
                                    </Button>
                                )
                            }

                        </>
                    }
                />
            </>
        )
    }

}

export default StepMemoryCheckFirst;