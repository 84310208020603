import React from 'react'
import BaseComponent from '../../../common/base';
import PartialTrailEngine from './partials/engine';

export class StepTrailA extends BaseComponent {

    constructor(props) {
        super(props);
        this.buttons = [
            { id: this.t(`questionnaire.char.1`) },
            { id: this.t(`questionnaire.char.2`) },
            { id: this.t(`questionnaire.char.3`) },
            { id: this.t(`questionnaire.char.4`) },
            { id: this.t(`questionnaire.char.5`) },
            { id: this.t(`questionnaire.char.6`) },
            { id: this.t(`questionnaire.char.7`) },
            { id: this.t(`questionnaire.char.8`) },
            { id: this.t(`questionnaire.char.9`) },
            { id: this.t(`questionnaire.char.10`) },
            { id: this.t(`questionnaire.char.11`) },
            { id: this.t(`questionnaire.char.12`) },
            { id: this.t(`questionnaire.char.13`) },
            { id: this.t(`questionnaire.char.14`) },
            { id: this.t(`questionnaire.char.15`) },
        ]
    }

    render() {
        return (
            <>
                <PartialTrailEngine
                    ident="A"
                    buttons={this.buttons}
                    title={this.t(`questionnaire.trail.A.task.title`)}
                    {...this.props}
                />
            </>
        )
    }

}

export default StepTrailA;