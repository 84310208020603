import React from 'react'
import BaseComponent from '../../../../common/base';
import { Card, Form, Button } from 'react-bootstrap';
import QuestionnairePartialCard from '../../../partials/card';

export class StepMemoryCheckSecond extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = Object.assign(
            this.state,
            {
                first: "",
                second: "",
                third: "",
                fourth: "",
                fifth: ""
            }
        )

    }

    onSubmit(e) {

        const { first, second, third, fourth, fifth } = this.state;
        
        const items = [
            { ident: "first", correct: this.normalizeWord(first) === this.normalizeWord(this.t('questionnaire.memory.word.face')) },
            { ident: "second", correct: this.normalizeWord(second) === this.normalizeWord(this.t('questionnaire.memory.word.velvet')) },
            { ident: "third", correct: this.normalizeWord(third) === this.normalizeWord(this.t('questionnaire.memory.word.church')) },
            { ident: "fourth", correct: this.normalizeWord(fourth) === this.normalizeWord(this.t('questionnaire.memory.word.daisy')) },
            { ident: "fifth", correct: this.normalizeWord(fifth) === this.normalizeWord(this.t('questionnaire.memory.word.red')) },
        ];

        const errorCount = items.filter(itm => itm.correct === false).length;

        this.props.dbSet('memory.second.reactionTime', this.firstReactionTime());
        this.props.dbSet('memory.second.responseTime', this.sinceMount());
        this.props.dbSet('memory.second.errorCount', errorCount);
        this.props.dbSet('memory.second.answers', [first, second, third, fourth, fifth]);
        this.props.nextStep();
    }

    render() {

        const items = [
            { ident: "first" },
            { ident: "second" },
            { ident: "third" },
            { ident: "fourth" },
            { ident: "fifth" },
        ];

        return (
            <>
                <QuestionnairePartialCard
                    onSubmit={(e) => this.onSubmit(e)}
                    title={this.t('questionnaire.memory.check.second.title')}
                    body={
                        <>
                            <Card.Text>
                                {this.t(`questionnaire.memory.check.second.question.01.${this.props.gender}`)}
                            </Card.Text>

                            <Card.Text>
                                {this.t(`questionnaire.memory.check.second.question.02.${this.props.gender}`)}
                            </Card.Text>

                            {items.map((itm) => {
                                return (
                                    <Form.Group key={itm.ident}>
                                        <Form.Control
                                            type="text"
                                            placeholder={this.t(`questionnaire.memory.check.placeholder.${itm.ident}`)}
                                            value={this.state[itm.ident]}
                                            onChange={
                                                (e) => {
                                                    this.measureReaction();
                                                    this.setState({ [itm.ident]: e.target.value })
                                                }
                                            }
                                        />
                                    </Form.Group>
                                )
                            })}

                        </>
                    }
                    footer={
                        <Button type="submit" variant="primary">
                            {this.t('questionnaire.common.saveAndNextStep')}
                        </Button>
                    }
                />
            </>
        )
    }

}

export default StepMemoryCheckSecond;