import React from 'react'
import BaseComponent from '../../../common/base';
import { Card, Form, Button } from 'react-bootstrap';
import QuestionnairePartialCard from '../../partials/card';

export class StepOrientationCity extends BaseComponent {

    onSubmit(e) {
        this.props.dbSet('orientation.city.reactionTime', this.firstReactionTime());
        this.props.dbSet('orientation.city.responseTime', this.sinceMount());
        this.props.dbSet('orientation.city.answer', this.normalizeWord(this.state.answer));
        this.props.nextStep();
    }

    render() {
        return (
            <>
                <QuestionnairePartialCard
                    onSubmit={(e) => this.onSubmit(e)}
                    title={this.t('questionnaire.orientation.city.title')}
                    body={
                        <>
                            <Card.Text>
                                {this.t(`questionnaire.orientation.city.question.${this.props.gender}`)}
                            </Card.Text>

                            <Form.Group>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder={this.t(`questionnaire.common.typeName`)}
                                    value={this.state.answer}
                                    onChange={(e) => this.onChange('answer', e)}
                                />
                            </Form.Group>
                        </>
                    }
                    footer={
                        <Button type="submit" variant="primary">
                            {this.t(`questionnaire.common.saveAndNextStep`)}
                        </Button>
                    }
                />
            </>
        )
    }

}

export default StepOrientationCity;