import React from 'react'
import BaseComponent from '../../../common/base';
import { Card, Button } from 'react-bootstrap';
import QuestionnairePartialCard from '../../partials/card';
import howtoGif from '../../../../assets/images/trailB.gif'

export class StepTrailIntroductionB extends BaseComponent {

    render() {
        return (
            <>
                <QuestionnairePartialCard
                    title={this.t(`questionnaire.trail.B.introduction.title`)}
                    body={
                        <>
                            <Card.Text>
                                {this.t(`questionnaire.trail.B.introduction.text.01`)}
                            </Card.Text>

                            <Card.Text>
                                {this.t(`questionnaire.trail.B.introduction.text.02`)}
                            </Card.Text>

                            <Card.Text>
                                {this.t(`questionnaire.trail.B.introduction.text.03`)}
                            </Card.Text>

                            <div className="text-center bg-light pt-3 pb-3 mb-3">
                                <h3>{this.t(`questionnaire.common.example`)}:</h3>
                                <img src={howtoGif} alt="" className="img-fluid" />
                            </div>

                            <Button variant="primary" onClick={(e) => this.props.nextStep(e)}>
                                {this.t('questionnaire.common.startTask')}
                            </Button>
                        </>
                    }
                />
            </>
        )
    }

}

export default StepTrailIntroductionB;