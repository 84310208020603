import React from 'react'
import BaseComponent from '../../../common/base';
import { Card, Form, Button, Col } from 'react-bootstrap';
import QuestionnairePartialCard from '../../partials/card';

export class StepHealthFitness extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = Object.assign(
            this.state,
            {
                hours: "",
                minutes: ""
            }
        );
    }

    onSubmit(e) {
        this.props.dbSet('health.fitness.reactionTime', this.firstReactionTime());
        this.props.dbSet('health.fitness.responseTime', this.sinceMount());
        this.props.dbSet('health.fitness.answer', (parseFloat(this.state.hours) * 60) + parseFloat(this.state.minutes));
        this.props.nextStep();
    }

    render() {
        return (
            <>
                <QuestionnairePartialCard
                    onSubmit={(e) => this.onSubmit(e)}
                    title={this.t('questionnaire.health.fitness.title')}
                    body={
                        <>
                            <Card.Text>
                                {this.t(`questionnaire.health.fitness.question.${this.props.gender}`)}
                            </Card.Text>

                            <Form.Group>
                                <Form.Row>
                                    <Form.Label column lg={4}>
                                        {this.t(`questionnaire.health.fitness.answer.hours`)}
                                    </Form.Label>
                                    <Col>
                                        <Form.Control
                                            required
                                            type="number"
                                            min={0}
                                            placeholder={this.t(`questionnaire.common.typeValue`)}
                                            value={this.state.hours}
                                            onChange={(e) => this.onChange('hours', e)}
                                        />
                                    </Col>
                                </Form.Row>
                            </Form.Group>

                            <Form.Group>
                                <Form.Row>
                                    <Form.Label column lg={4}>
                                        {this.t(`questionnaire.health.fitness.answer.minutes`)}
                                    </Form.Label>
                                    <Col>
                                        <Form.Control
                                            required
                                            type="number"
                                            min={0}
                                            placeholder={this.t(`questionnaire.common.typeValue`)}
                                            value={this.state.minutes}
                                            onChange={(e) => this.onChange('minutes', e)}
                                        />
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                        </>
                    }
                    footer={
                        <Button type="submit" variant="primary">
                            {this.t(`questionnaire.common.saveAndNextStep`)}
                        </Button>
                    }
                />
            </>
        )
    }

}

export default StepHealthFitness;