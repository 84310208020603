import React from 'react'
import BaseComponent from '../../common/base';
import { Button, Card, Form, Modal, Row, Col } from 'react-bootstrap';

export class SubscribePatient extends BaseComponent {

    constructor(props) {
        super(props);

        this.baseState = {
            showModal: false,
            isLoading: false,
            redcapDomain: "pja",
            redcapSupervisorEmailAddress: "andrzej.przybyszewski@umassmed.edu",
            redcapRecordId: "",
            region: this.props.region || "eu",
            gender: "",
            age: '',
            mode: "manual",
            version: "01",
            consent: [
                'toParticipate',
                'toEmailProcessing',
                'toVoiceProcessing',
                'toImageProcessing',
                'toResultsProcessing'
            ],
            createdUrl: "",
            createdIdent: ""
        };

        this.state = Object.assign(
            this.state,
            this.baseState
        );

    }

    onSubmit(e) {
        e.preventDefault();
        let self = this;
        let baseUrl = `${process.env.REACT_APP_URL}/${self.state.region}/${self.props.language}/${self.state.version}/`;
        self.setState({ isLoading: true });
        self.props.showToast({ type: 'info', txt: this.t('landing.form.popup.saving') });
        self.api.request(
            `/participant/subscribe?language=${self.props.language}&region=${self.state.region}`,
            {
                'participant': {
                    'redcapDomain': self.state.redcapDomain,
                    'redcapSupervisorEmailAddress': self.state.redcapSupervisorEmailAddress,
                    'redcapRecordId': self.state.redcapRecordId,
                    'gender': self.state.gender,
                    'age': self.state.age,
                    'language': self.props.language,
                    'region': self.state.region,
                    'consentToParticipate': self.state.consent.includes('toParticipate'),
                    'consentToEmailProcessing': self.state.consent.includes('toEmailProcessing'),
                    'consentToVoiceProcessing': self.state.consent.includes('toVoiceProcessing'),
                    'consentToImageProcessing': self.state.consent.includes('toImageProcessing'),
                    'consentToResultsProcessing': self.state.consent.includes('toResultsProcessing'),
                    'confirmation/return/url': self.state.emailAddress,
                },
                'questionnaire': {
                    'version': self.state.version
                }
            },
            (data) => {
                self.props.showToast({
                    type: 'success',
                    txt: this.t('landing.form.popup.saved')
                });
                self.setState({
                    isLoading: false,
                    showModal: true,
                    createdUrl: baseUrl + data.questionnaire.uuid,
                    createdIdent: data.questionnaire.ident
                });
            },
            (details) => {
                self.props.showToast({
                    type: 'error',
                    txt: details.data.error
                });
                self.setState({
                    isLoading: false
                });
            }
        );
    }

    render() {

        const modal = (
            <Modal
                size="lg"
                centered
                show={this.state.showModal}
                onHide={() => this.setState({ showModal: false })}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
                    <p>{this.t('landing.form.modal.text.01')}</p>
                    <Form.Control
                        required
                        readOnly={true}
                        as="textarea"
                        rows={3}
                        value={this.state.createdUrl}
                        onChange={(e) => { }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={
                            (e) =>
                                this.setState(
                                    {
                                        showModal: false
                                    }
                                )
                        }
                    >
                        {this.t('landing.form.modal.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )

        const formBody = (
            <>
                <Row>
                    <Col sm={12} lg={6}>
                        <Form.Group>
                            <Form.Label>
                                {this.t('landing.form.redcapDomain.label')}
                            </Form.Label>
                            <Form.Control
                                required
                                as="select"
                                value={this.state.redcapDomain}
                                onChange={(e) => {
                                    let unitName = this.onChange('redcapDomain', e);
                                    if (["umass"].includes(unitName)) {
                                        this.setState({ region: "us" });
                                    } else {
                                        this.setState({ region: "eu" });
                                    }
                                }}
                            >
                                <option value="umass">
                                    {this.t('landing.form.redcapDomain.option.umass')}
                                </option>
                                <option value="cskmswia">
                                    {this.t('landing.form.redcapDomain.option.cskmswia')}
                                </option>
                                <option value="brodno">
                                    {this.t('landing.form.redcapDomain.option.brodno')}
                                </option>
                                <option value="pja">
                                    {this.t('landing.form.redcapDomain.option.pja')}
                                </option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col sm={12} lg={6}>
                        <Form.Group>
                            <Form.Label>
                                {this.t('landing.form.redcapSupervisorEmailAddress.label')}
                            </Form.Label>
                            <Form.Control
                                required
                                type="email"
                                placeholder={this.t('landing.form.redcapSupervisorEmailAddress.placeholder')}
                                value={this.state.redcapSupervisorEmailAddress}
                                onChange={(e) => this.onChange('redcapSupervisorEmailAddress', e)}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} lg={6}>
                        <Form.Group>
                            <Form.Label>
                                {this.t('landing.form.redcapRecordId.label')}
                            </Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder={this.t('landing.form.redcapRecordId.placeholder')}
                                value={this.state.redcapRecordId}
                                onChange={(e) => this.onChange('redcapRecordId', e)}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={12} lg={6}>
                        <Form.Group>
                            <Form.Label>
                                {this.t('landing.form.region.label')}
                            </Form.Label>
                            <Form.Control
                                required
                                as="select"
                                value={this.state.region}
                                onChange={(e) => this.onChange('region', e)}
                            >
                                <option value="us">
                                    {this.t('landing.form.region.option.us')}
                                </option>
                                <option value="eu">
                                    {this.t('landing.form.region.option.eu')}
                                </option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} lg={6}>
                        <Form.Group>
                            <Form.Label>
                                {this.t('landing.form.age.label')}
                            </Form.Label>
                            <Form.Control
                                required={false}
                                type="number"
                                min="18"
                                max="128"
                                placeholder={this.t('landing.form.age.placeholder')}
                                value={this.state.age}
                                onChange={(e) => this.onChange('age', e)}
                            />
                        </Form.Group>
                    </Col>

                    <Col sm={12} lg={6}>
                        <Form.Group>
                            <Form.Label className="mr-3">
                                {this.t('landing.form.gender.label')}
                            </Form.Label>
                            <Form.Check
                                custom
                                inline
                                required
                                type="radio"
                                name="gender"
                                id="gender-female"
                                value="f"
                                label={this.t('landing.form.gender.female')}
                                checked={this.state.gender === 'f'}
                                onChange={(e) => this.onChange('gender', e)}
                            />
                            <Form.Check
                                custom
                                inline
                                required
                                type="radio"
                                name="gender"
                                id="gender-male"
                                value="m"
                                label={this.t('landing.form.gender.male')}
                                checked={this.state.gender === 'm'}
                                onChange={(e) => this.onChange('gender', e)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </>
        )

        return (
            <>
                {modal}
                <Form method="post" onSubmit={(e) => this.onSubmit(e)} >
                    <Card className="card-enrol">
                        <Card.Header>
                            {this.t('landing.form.header')}
                        </Card.Header>
                        <Card.Body>
                            {formBody}
                        </Card.Body>
                        <Card.Footer className="text-muted text-center" style={{ fontSize: "0.8rem" }}>
                            <div className="text-center">
                                <Button variant="purple" size="lg" type="submit" className="pl-5 pr-5 pt-2 pb-2" disabled={this.state.isLoading}>
                                    {this.t('landing.form.submit')}
                                </Button>
                            </div>
                        </Card.Footer>
                    </Card>
                </Form>
            </>
        )
    }
}

export default SubscribePatient