import React from 'react'
import BaseComponent from '../../../common/base';
import { Card, Button } from 'react-bootstrap';
import QuestionnairePartialCard from '../../partials/card';
import coverPhoto from '../../../../assets/images/settings3x.png'

export class StepPreparationWelcome extends BaseComponent {

    nextStep(e) {
        this.props.dbSet('device.screenWidth', window.screen.width);
        this.props.dbSet('device.screenHeight', window.screen.height);
        this.props.dbSet('device.userAgent', navigator.userAgent);
        this.props.nextStep(e);
    }

    render() {
        return (
            <>
                <QuestionnairePartialCard
                    img={coverPhoto}
                    title={this.t('questionnaire.preparation.welcome.title')}
                    body={
                        <>
                            <Card.Text>
                                {this.t('questionnaire.preparation.welcome.text')}
                            </Card.Text>
                            <Button variant="primary" onClick={(e) => this.nextStep()}>
                                {this.t('questionnaire.common.nextStep')}
                            </Button>
                        </>
                    }
                />
            </>
        )
    }

}

export default StepPreparationWelcome;