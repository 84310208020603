const preloadImages = (srcArray) => {
    var loadImage = (src) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                resolve(img);
            };
            img.onerror = img.onabort = () => {
                reject(src);
            };
            img.src = src;
        });
    }
    return srcArray.map(src => loadImage(src));
};

export default preloadImages;