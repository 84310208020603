import React from 'react'
import BaseComponent from '../../../common/base';
import { Card, Button } from 'react-bootstrap';
import QuestionnairePartialCard from '../../partials/card';
import { ReactComponent as CoverPhoto } from '../../../../assets/images/cube.svg'
import CanvasDraw from "react-canvas-draw";

export class StepDrawCube extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = Object.assign(
            this.state,
            {
                color: "#000000",
                width: `100%`,
                height: `50vh`,
                brushRadius: 4,
                lazyRadius: 0
            }
        );

        this.canvasDraw = React.createRef();
    }

    pathToXY(path, desiredIntersections) {
        let totalLength = path.getTotalLength();
        let intersections = Math.min(totalLength, desiredIntersections || totalLength);
        let points = [];
        for (var i = 0; i <= intersections; i++) {
            let distance = i * 1 / intersections * totalLength;
            let point = path.getPointAtLength(distance);
            points.push({ x: point.x, y: point.y });
        }
        return points;
    }

    onSubmit(e) {
        e.preventDefault();
        this.props.dbSet('draw.cube.reactionTime', this.firstReactionTime());
        this.props.dbSet('draw.cube.responseTime', this.sinceMount());
        this.props.dbSet('draw.cube.pictureData', JSON.parse(this.canvasDraw.getSaveData()));
        this.props.nextStep();
    }

    render() {
        return (
            <>
                <QuestionnairePartialCard
                    onSubmit={(e) => this.onSubmit(e)}
                    side={<CoverPhoto />}
                    title={this.t(`questionnaire.draw.cube.title`)}
                    maxWidth="40rem"
                    body={
                        <>
                            <Card.Text>
                                {this.t(`questionnaire.draw.cube.text`)}
                            </Card.Text>
                            <CanvasDraw
                                ref={canvasDraw => (this.canvasDraw = canvasDraw)}
                                brushColor={this.state.color}
                                brushRadius={this.state.brushRadius}
                                lazyRadius={this.state.lazyRadius}
                                canvasWidth={this.state.width}
                                canvasHeight={this.state.height}
                            />
                        </>
                    }
                    footer={
                        <Button type="submit" variant="primary">
                            {this.t(`questionnaire.common.saveAndNextStep`)}
                        </Button>
                    }
                />
            </>
        )
    }

}

export default StepDrawCube;