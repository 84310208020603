import React from 'react'
import BaseComponent from '../../../common/base';
import { Card, Form, Button } from 'react-bootstrap';
import QuestionnairePartialCard from '../../partials/card';

export class StepHealthFocus extends BaseComponent {

    onSubmit(e) {
        this.props.dbSet('health.focus.reactionTime', this.firstReactionTime());
        this.props.dbSet('health.focus.responseTime', this.sinceMount());
        this.props.dbSet('health.focus.answer', this.state.answer);
        this.props.nextStep();
    }

    render() {
        return (
            <>
                <QuestionnairePartialCard
                    onSubmit={(e) => this.onSubmit(e)}
                    title={this.t(`questionnaire.health.focus.title`)}
                    body={
                        <>
                            <Card.Text>
                                {this.t(`questionnaire.health.focus.question.${this.props.gender}`)}
                            </Card.Text>

                            {
                                ['often', 'sometimes', 'never'].map(itm => {
                                    return (
                                        <Form.Group key={`radio-health-focus-${itm}`}>
                                            <Form.Check
                                                custom
                                                required
                                                type={'radio'}
                                                name={`health-focus`}
                                                value={itm}
                                                id={`radio-health-focus-${itm}`}
                                                label={this.t(`questionnaire.health.focus.answer.${itm}`)}
                                                checked={this.state.answer === itm}
                                                onChange={(e) => this.onChange('answer', e)}
                                            />
                                        </Form.Group>
                                    )
                                })
                            }
                        </>
                    }
                    footer={
                        <Button type="submit" variant="primary">
                            {this.t(`questionnaire.common.saveAndNextStep`)}
                        </Button>
                    }
                />
            </>
        )
    }

}

export default StepHealthFocus;