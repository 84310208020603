import React from 'react'
import BaseComponent from '../common/base';
import { Container, Row, Col, Navbar } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import Footer from './partials/footer';
import SubscribePatient from './partials/subscribe-patient';
import imgLogo from '../../assets/images/icon_256x256.png'

export class PatientPage extends BaseComponent {

    render() {
        return (
            <>
                <Helmet>
                    <title>{this.t('landing.helmet.title')}</title>
                    <meta name="description" content={this.t('landing.helmet.description')} />
                </Helmet>

                <Container className="landing-page">

                    <Row
                        id={this.t('landing.navbar.link.section_navbar.href')}
                        ref={this.section_navbar}
                    >
                        <Col>
                            <Navbar expand="lg" className="p-0 pt-4 pb-4">
                                <Navbar.Brand href='/'>
                                    <img
                                        alt=""
                                        src={imgLogo}
                                        className="logo d-inline-block align-top"
                                    />
                                    &nbsp;
                                    {this.t('landing.navbar.title')}
                                    <span className="d-none d-sm-none d-md-none d-lg-none d-xl-inline">
                                        {this.t('landing.navbar.slogan')}
                                    </span>
                                </Navbar.Brand>
                            </Navbar>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <SubscribePatient {...this.props} />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Footer {...this.props} link={'participant'} />
                        </Col>
                    </Row>

                </Container>
            </>
        )
    }
}

export default PatientPage
