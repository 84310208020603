
import _ from 'lodash';

class Database {

    constructor() {
        this.data = {
            step: 0,
            progress: 0,
            gender: null,
            age: null,
            device: {
                screenWidth: null,
                screenHeight: null,
                userAgent: null
            },
            geolocation: {
                lat: null,
                lng: null,
                acc: null,
                ts: null,
                city: null,
                country: null,
                isWorking: false,
                isSupported: false
            },
            consent: {
                toParticipate: true,
                toEmailProcessing: true,
                toVoiceProcessing: true,
                toImageProcessing: true,
                toResultsProcessing: true
            },
            camera: {
                isWorking: false,
            },
            microphone: {
                isWorking: false,
                recordingData: null
            },
            sound: {
                isWorking: false,
                isVerified: false,
            },
            fullScreen: {
                isWorking: false
            },
            health: {
                overall: {
                    question: "How do you evaluate your current health condition?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                illness: {
                    question: "What kind of diseases do you suffer from?",
                    reactionTime: null,
                    responseTime: null,
                    answers: []
                },
                fitness: {
                    question: "How much time (average) per day you spend on physical activity (e.g., carrying light items, cycling, walking)?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                focus: {
                    question: "In the last 30 days, have you experienced problems with attention (for example, while watching TV or reading)?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
            },
            pdq8: {
                q01: {
                    question: "In the last 30 days, have you had difficulty getting around in public places?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q02: {
                    question: "In the last 30 days, have you had difficulty dressing?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q03: {
                    question: "In the last 30 days, have you felt depressed?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q04: {
                    question: "In the last 30 days, have you had problems with your close personal relationships?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q05: {
                    question: "In the last 30 days, have you had problems with your concentration, for example when reading or watching TV?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q06: {
                    question: "In the last 30 days, have you felt unable to communicate effectively?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q07: {
                    question: "In the last 30 days, have you had painful muscle cramps or spasms?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q08: {
                    question: "In the last 30 days, have you felt embarrassed in public?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                }
            },
            gds15: {
                q01: {
                    question: "Are you basically satisfied with your life?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q02: {
                    question: "Have you dropped many of your activities or interests?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q03: {
                    question: "Do you feel that your life is empty?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q04: {
                    question: "Do you often get bored?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q05: {
                    question: "Are you in good spirits most of the time?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q06: {
                    question: "Are you afraid that something bad is going to happen to you?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q07: {
                    question: "Do you feel happy most of the time?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q08: {
                    question: "Do you feel helpless?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q09: {
                    question: "Do you prefer to stay at home, rather than go out and do things?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q10: {
                    question: "Do you feel that you have more problems with memory than most?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q11: {
                    question: "Do you think it is wonderful to be alive now?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q12: {
                    question: "Do you feel pretty worthless the way you are now?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q13: {
                    question: "Do you feel full of energy?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q14: {
                    question: "Do you feel that your situation is hopeless?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q15: {
                    question: "Do you think that most people are better off then you are?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                }
            },
            trail: {
                A: {
                    question: "Pick the points in the order 1 - 2 - 3.",
                    reactionTime: null,
                    responseTime: null,
                    skipped: false,
                    errorCount: null
                },
                B: {
                    question: "Pick the points in the order 1 - A - 2 - B.",
                    reactionTime: null,
                    responseTime: null,
                    skipped: false,
                    errorCount: null
                },
                BShort: {
                    question: "Pick the points in the order 1 - A - 2 - B.",
                    reactionTime: null,
                    responseTime: null,
                    skipped: false,
                    errorCount: null
                }
            },
            draw: {
                cube: {
                    question: "Redraw the figure.",
                    reactionTime: null,
                    responseTime: null,
                    pictureData: null
                },
                clock: {
                    question: "Draw a clock.",
                    reactionTime: null,
                    responseTime: null,
                    pictureData: null
                }
            },
            naming: {
                cow: {
                    question: "Please name this animal in one word (case insensitive).",
                    reactionTime: null,
                    responseTime: null,
                    isCorrect: null,
                    answer: null
                },
                horse: {
                    question: "Please name this animal in one word (case insensitive).",
                    reactionTime: null,
                    responseTime: null,
                    isCorrect: null,
                    answer: null
                },
                lion: {
                    question: "Please name this animal in one word (case insensitive).",
                    reactionTime: null,
                    responseTime: null,
                    isCorrect: null,
                    answer: null
                },
            },
            memory: {
                first: {
                    question: "Please enter the words in the order in which they were displayed",
                    errorCount: null,
                    reactionTime: null,
                    responseTime: null
                },
                second: {
                    question: "Earlier, we asked you to remember five words. Now please enter the words in the order they were shown.",
                    errorCount: null,
                    reactionTime: null,
                    responseTime: null,
                    answers: []
                },
                numbersAsc: {
                    question: "Please enter the digits in the order in which they are displayed.",
                    errorCount: null,
                    reactionTime: null,
                    responseTime: null,
                    answers: []
                },
                numbersDesc: {
                    question: "Please enter the digits in REVERSE the order they are displayed.",
                    errorCount: null,
                    reactionTime: null,
                    responseTime: null,
                    answers: []
                },
                sentenceFirst: {
                    question: "Repeat: I only know that John is the one to help today.",
                    errorCount: null,
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                sentenceSecond: {
                    question: "Repeat: The cat always hid under the couch when dogs were in the room.",
                    errorCount: null,
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
            },
            attention: {
                letters: {
                    question: "Please click the button each time the letter A is displayed.",
                    errorCount: null,
                    reactionTimeAvg: null
                }
            },
            counting: {
                first: {
                    question: "Please enter the result of the operation and remember it (100 - 7).",
                    reactionTime: null,
                    responseTime: null,
                    isCorrect: null,
                    answer: null
                },
                second: {
                    question: "Please subtract 7 from the last given result.",
                    reactionTime: null,
                    responseTime: null,
                    isCorrect: null,
                    answer: null
                },
                third: {
                    question: "Please subtract 7 from the last given result.",
                    reactionTime: null,
                    responseTime: null,
                    isCorrect: null,
                    answer: null
                },
                fourth: {
                    question: "Please subtract 7 from the last given result.",
                    reactionTime: null,
                    responseTime: null,
                    isCorrect: null,
                    answer: null
                },
                fifth: {
                    question: "Please subtract 7 from the last given result.",
                    reactionTime: null,
                    responseTime: null,
                    isCorrect: null,
                    answer: null
                }
            },
            fluency: {
                speech: {
                    question: "Please say as many English words as possible, that start with the letter F.",
                    recordingData: null
                },
                typing: {
                    question: "Please write as many English words as possible, that start with the letter F.",
                    reactionTime: null,
                    responseTime: null,
                    answers: []
                }
            },
            stroop: {
                question: "Please select the names of the colors in which the words are displayed, regardless of the meaning of the words themselves.",
                errorCount: null,
                reactionTimeAvg: null
            },
            abstraction: {
                transportation: {
                    question: "What do these words have in common? (train + bike).",
                    reactionTime: null,
                    responseTime: null,
                    isCorrect: null,
                    answer: null
                },
                measurement: {
                    question: "What do these words have in common? (watch + ruler).",
                    reactionTime: null,
                    responseTime: null,
                    isCorrect: null,
                    answer: null
                }
            },
            orientation: {
                year: {
                    question: "What is the current YEAR?",
                    reactionTime: null,
                    responseTime: null,
                    isCorrect: null,
                    answer: null
                },
                season: {
                    question: "What is the current SEASON?",
                    reactionTime: null,
                    responseTime: null,
                    isCorrect: null,
                    answer: null
                },
                month: {
                    question: "What is the current MONTH?",
                    reactionTime: null,
                    responseTime: null,
                    isCorrect: null,
                    answer: null
                },
                monthDay: {
                    question: "What is the DAY OF THE MONTH today?",
                    reactionTime: null,
                    responseTime: null,
                    isCorrect: null,
                    answer: null
                },
                weekDay: {
                    question: "What is the DAY OF THE WEEK today?",
                    reactionTime: null,
                    responseTime: null,
                    isCorrect: null,
                    answer: null
                },
                country: {
                    question: "In what COUNTRY are you now?",
                    reactionTime: null,
                    responseTime: null,
                    isCorrect: null,
                    answer: null
                },
                city: {
                    question: "In what CITY are you now?",
                    reactionTime: null,
                    responseTime: null,
                    isCorrect: null,
                    answer: null
                },
                place: {
                    question: "In what PLACE are you now?",
                    reactionTime: null,
                    responseTime: null,
                    isCorrect: null,
                    answer: null
                }
            },
            sleep: {
                general: {
                    question: "How do you sleep?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                epworth: {
                    q01: {
                        question: "Please rate the likelihood of falling asleep while performing this activity: Sitting and reading.",
                        reactionTime: null,
                        responseTime: null,
                        answer: null
                    },
                    q02: {
                        question: "Please rate the likelihood of falling asleep while performing this activity: Watching TV.",
                        reactionTime: null,
                        responseTime: null,
                        answer: null
                    },
                    q03: {
                        question: "Please rate the likelihood of falling asleep while performing this activity: Sitting in public places (e.g. theater, meetings).",
                        reactionTime: null,
                        responseTime: null,
                        answer: null
                    },
                    q04: {
                        question: "Please rate the likelihood of falling asleep while performing this activity: While being a passenger in a car, driving for an hour without rest.",
                        reactionTime: null,
                        responseTime: null,
                        answer: null
                    },
                    q05: {
                        question: "Please rate the likelihood of falling asleep while performing this activity: Lying down and resting in the afternoon.",
                        reactionTime: null,
                        responseTime: null,
                        answer: null
                    },
                    q06: {
                        question: "Please rate the likelihood of falling asleep while performing this activity: While talking, sitting.",
                        reactionTime: null,
                        responseTime: null,
                        answer: null
                    },
                    q07: {
                        question: "Please rate the likelihood of falling asleep while performing this activity: Relaxing after lunch without alcohol.",
                        reactionTime: null,
                        responseTime: null,
                        answer: null
                    },
                    q08: {
                        question: "Please rate the likelihood of falling asleep while performing this activity: In the car, when parked in a traffic jam or at a red light for several minutes.",
                        reactionTime: null,
                        responseTime: null,
                        answer: null
                    }
                }
            },
            facialExpressionRecognition: {
                happiness01: {
                    question: "What emotion does this character express?",
                    reactionTime: null,
                    responseTime: null,
                    isCorrect: null,
                    answer: null
                },
                anger01: {
                    question: "What emotion does this character express?",
                    reactionTime: null,
                    responseTime: null,
                    isCorrect: null,
                    answer: null
                },
                sadness01: {
                    question: "What emotion does this character express?",
                    reactionTime: null,
                    responseTime: null,
                    isCorrect: null,
                    answer: null
                },
                happiness02: {
                    question: "What emotion does this character express?",
                    reactionTime: null,
                    responseTime: null,
                    isCorrect: null,
                    answer: null
                },
                disgust01: {
                    question: "What emotion does this character express?",
                    reactionTime: null,
                    responseTime: null,
                    isCorrect: null,
                    answer: null
                },
                surprise01: {
                    question: "What emotion does this character express?",
                    reactionTime: null,
                    responseTime: null,
                    isCorrect: null,
                    answer: null
                },
                fear01: {
                    question: "What emotion does this character express?",
                    reactionTime: null,
                    responseTime: null,
                    isCorrect: null,
                    answer: null
                }
            },
            spontaneousEmotionalReaction: {
                question: "During the movie playback, we will register your face to analyze your reactions to the expressions of emotions.",
                recordingData: null
            },
            csat: {
                question: "Survey.",
                reactionTime: null,
                responseTime: null,
                answers: []
            },
            /*
            quiprs: {
                info: "Currently not used!",
                q01: {
                    question: "How often do intrusive thoughts or guilt about gambling bother you?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q02: {
                    question: "How often do intrusive thoughts or guilt about sexual activities bother you?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q03: {
                    question: "How often do intrusive thoughts or guilt about shopping bother you?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q04: {
                    question: "How often do intrusive thoughts or guilt about eating bother you?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q05: {
                    question: "How often do intrusive thoughts or guilt about tasks or hobbies bother you?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q06: {
                    question: "How often do intrusive thoughts or guilt about repetitive activities bother you?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q07: {
                    question: "How often do intrusive thoughts or guilt about medication intake bother you?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q08: {
                    question: "Do you feel distressed by excessive urges to gamble?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q09: {
                    question: "Do you feel distressed by excessive urges for sexual activities?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q10: {
                    question: "Do you feel distressed by excessive urges to shop?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q11: {
                    question: "Do you feel distressed by excessive urges to eat?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q12: {
                    question: "Do you feel distressed by excessive urges for tasks or hobbies?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q13: {
                    question: "Do you feel distressed by excessive urges for repetitive activities?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q14: {
                    question: "Do you feel distressed by excessive urges for medication intake?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q15: {
                    question: "Is it difficult to moderate your gambling activities?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q16: {
                    question: "Is it difficult to moderate your sexual activities?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q17: {
                    question: "Is it difficult to moderate your shopping activities?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q18: {
                    question: "Is it difficult to moderate your eating habits?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q19: {
                    question: "Is it difficult to moderate your engagement in tasks or hobbies?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q20: {
                    question: "Is it difficult to moderate your engagement in repetitive activities?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q21: {
                    question: "Is it difficult to moderate your medication intake?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q22: {
                    question: "Do you take covert actions to maintain your gambling habits?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q23: {
                    question: "Do you take covert actions to maintain your sexual activities?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q24: {
                    question: "Do you take covert actions to maintain your shopping habits?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q25: {
                    question: "Do you take covert actions to maintain your eating habits?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q26: {
                    question: "Do you take covert actions to maintain your tasks or hobbies?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q27: {
                    question: "Do you take covert actions to maintain repetitive activities?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                },
                q28: {
                    question: "Do you take covert actions to maintain your medication regimen?",
                    reactionTime: null,
                    responseTime: null,
                    answer: null
                }
            }
            */
        }
    }

    get(key, defaultValue) {
        return key ? _.get(this.data, key) || defaultValue : this.data;
    }

    set(key, value) {
        _.set(this.data, key, value);
        return this.get(key);
    }

    assign(obj) {
        this.data =
            Object.assign(
                this.data,
                obj
            );
        return this.data;
    }

}

export default Database;