import React from 'react'
import BaseComponent from '../../../common/base';
import { Card, Form, Button } from 'react-bootstrap';
import QuestionnairePartialCard from '../../partials/card';

export class StepOrientationMonth extends BaseComponent {

    onSubmit(e) {
        this.props.dbSet('orientation.month.reactionTime', this.firstReactionTime());
        this.props.dbSet('orientation.month.responseTime', this.sinceMount());
        this.props.dbSet(
            'orientation.month.answer',
            parseInt(this.normalizeWord(this.state.answer), 10) + 1
        );
        this.props.dbSet(
            'orientation.month.isCorrect',
            parseInt(this.normalizeWord(this.state.answer), 10) === new Date().getMonth()
        );
        this.props.nextStep();
    }

    render() {
        return (
            <>
                <QuestionnairePartialCard
                    onSubmit={(e) => this.onSubmit(e)}
                    title={this.t('questionnaire.orientation.month.title')}
                    body={
                        <>
                            <Card.Text>
                                {this.t(`questionnaire.orientation.month.question.${this.props.gender}`)}
                            </Card.Text>

                            <Form.Group>
                                <Form.Control
                                    as="select"
                                    required
                                    value={this.state.answer}
                                    onChange={(e) => this.onChange('answer', e)}
                                >
                                    <option value="">{this.t('questionnaire.orientation.month.placeholder')}</option>
                                    {
                                        [
                                            '0', '1', '2', '3', '4', '5', '6',
                                            '7', '8', '9', '10', '11'
                                        ].map((itm) => {
                                            return (
                                                <option
                                                    key={itm}
                                                    value={itm}
                                                >
                                                    {this.t(`questionnaire.orientation.month.option.${itm}`)}
                                                </option>
                                            );
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                        </>
                    }
                    footer={
                        <Button type="submit" variant="primary">
                            {this.t(`questionnaire.common.saveAndNextStep`)}
                        </Button>
                    }
                />
            </>
        )
    }

}

export default StepOrientationMonth;