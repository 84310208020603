import React, { Component } from "react";
import { transliterate as tr } from 'transliteration';

class BaseComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            answer: "",
            answers: []
        }
        this.i18n = props.i18n;
        this.api = props.api;
        this.moment = props.moment;
        this.gender = props.gender || "male";
        this.mounted = 0;
        this.reaction = [];
    }

    t() {
        const translated = this.props.t(arguments[0], { ...arguments[1], "context": this.gender });
        if (translated.includes('**') === false) {
            return translated;
        }
        const splitText = translated.split('**');
        return (
            <>
                {
                    splitText.map(
                        (text, i) => {
                            return (!!(i % 2)) ?
                                <b>{text}</b>
                                :
                                <>{text}</>
                        }
                    )
                }
            </>
        );
    }

    normalizeWord(word) {
        return tr(word.trim()).toLowerCase();
    }

    now() {
        return performance.now();
    }

    measureReaction() {
        this.reaction.push(this.sinceMount());
    }

    firstReactionTime() {
        return Math.round(this.reaction.length ? this.reaction[0] : 0);
    }

    averageReactionTime() {
        return Math.round(this.reaction.reduce((sume, el) => sume + el, 0) / this.reaction.length);
    }

    sinceMount() {
        return Math.round(this.now() - this.mounted);
    }

    resetTimers() {
        this.mounted = this.now();
        this.reaction = [];
    }

    componentDidMount() {
        this.resetTimers();
    }

    componentWillUnmount() {
        this.api.abort();
    }

    handleElementAdd(list, element) {
        this.setState({ [list]: this.state[list].concat([element]) });
    }

    handleElementChange(list, currentElement, field, e, explicit) {
        const newElements = this.state[list].map((element) => {
            if (element.uuid !== currentElement.uuid) return element;
            let updatedElement = { ...element };
            let newValue = this.getFieldEventValue(e, explicit);
            updatedElement[field] = newValue;
            return updatedElement;
        });
        this.setState({ [list]: newElements });
    }

    handleElementTrash(list, currentElement) {
        const newElements = this.state[list].map((element) => {
            if (element.uuid !== currentElement.uuid) return element;
            return { ...element, trash: true };
        });
        this.setState({ [list]: newElements });
    }

    getFieldEventValue(e, controlType) {
        if (['checkbox'].includes(controlType)) {
            return e.target.checked;
        }
        if (['radio'].includes(controlType)) {
            return e.target.value;
        }
        return e.target.value;
    }

    onCheckBoxChange(field, value) {
        this.measureReaction();
        let currentValue = this.state[field];
        let newValue = currentValue;
        if (currentValue.includes(value) === false) {
            newValue.push(value);
        } else {
            newValue = currentValue.filter(itm => itm !== value);
        }
        this.setState({ [field]: newValue });
    }

    onChange(field, e) {
        this.measureReaction();
        let controlType = e.target.type;
        let value = this.getFieldEventValue(e, controlType);
        this.setState({ [field]: value });
        return value;
    }

    clickActive = (event) => {
        document.querySelector(".nav-link").classList.remove('show');
        event.target.classList.add('show');
    }

    routeChange = (route) => {
        this.props.history.push(`${process.env.PUBLIC_URL}${route}`);
    }

    shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

}

export default BaseComponent;