// This must be the first line in src/index.js
import 'react-app-polyfill/ie11';

// React
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Router
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import moment from "moment-timezone";
import 'moment/min/locales';

// Notifications
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Loading
import LoadingBar from 'react-top-loading-bar';

// Components
import API from "./components/common/api";

// Translation
import i18n from "./components/common/i18n";

// CSS
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";

// Landing page
import LandingPage from './components/landing/landing-page';
import PatientPage from './components/landing/patient-page';
import NotFoundPage from './components/landing/not-found-page';
import Questionnaire01Page from './components/questionnaire/questionnaire-01-page';

import StudentPage from './components/landing/student-page';
import { TabletPage } from './components/landing/tablet-page';

class Root extends Component {

    constructor(props) {
        super(props);
        this.api = new API();
        this.moment = moment;
        this.regions = ["us", "eu"];
        this.languages = ["en", "pl", "uk", "ru"];
        this.state = {
            session: null,
            isLoading: true,
            region: this.api.resolveRegion({ "available": this.regions }),
            language: this.api.resolveLanguage({ "available": this.languages }),
        }
        this.baseUrl = `${process.env.PUBLIC_URL}/${this.state.region}/${this.state.language}/`;
        this.baseUrlPattern = `${process.env.PUBLIC_URL}/(${this.regions.join('|')})/(${this.languages.join('|')})`;
        this.handleApiProgress = this.handleApiProgress.bind(this);
        this.moment.locale(this.state.language);
        i18n.engine.changeLanguage(this.state.language);
        this.loading_bar = React.createRef();
    }

    componentDidMount() {
        window.addEventListener("API_PROGRESS", this.handleApiProgress, false);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("API_PROGRESS", this.handleApiProgress, false);
    }

    handleApiProgress(data) {
        let progress = Math.max(data.detail ? data.detail : 0, 33);
        this.loading_bar.current.continuousStart(progress);
    }

    showToast(data) {
        let type = data.type;
        switch (type) {
            case 'success':
                toast.success(data.txt);
                break;

            case 'info':
                toast.info(data.txt);
                break;

            case 'error':
            default:
                toast.error(data.txt);
                break;
        }
    }

    t(str) {
        return i18n.engine.t(str);
    }

    render() {

        const routing = (
            <BrowserRouter basename={`/`}>
                <Switch>

                    {/* default language redirect */}

                    <Redirect exact from={`${process.env.PUBLIC_URL}/`} to={this.baseUrl} />

                    {/* all routes */}

                    <Route
                        exact
                        path={`${this.baseUrlPattern}/01/:uuid`}
                        render={
                            props =>
                                <Questionnaire01Page
                                    t={this.t}
                                    i18n={i18n}
                                    api={this.api}
                                    moment={this.moment}
                                    language={this.state.language}
                                    region={this.state.region}
                                    baseUrl={this.baseUrl}
                                    showToast={(data) => { return this.showToast(data) }}
                                    {...props}
                                />
                        }
                    />

                    <Route
                        path={`${this.baseUrlPattern}/(pacjent|patient|participant)`}
                        render={
                            props =>
                                <PatientPage
                                    t={this.t}
                                    i18n={i18n}
                                    api={this.api}
                                    moment={this.moment}
                                    language={this.state.language}
                                    region={this.state.region}
                                    baseUrl={this.baseUrl}
                                    showToast={(data) => { return this.showToast(data) }}
                                    {...props}
                                />
                        }
                    />

                    <Route
                        path={`${this.baseUrlPattern}/(tablet)`}
                        render={
                            props =>
                                <TabletPage
                                    t={this.t}
                                    i18n={i18n}
                                    api={this.api}
                                    moment={this.moment}
                                    language={this.state.language}
                                    region={this.state.region}
                                    baseUrl={this.baseUrl}
                                    showToast={(data) => { return this.showToast(data) }}
                                    {...props}
                                />
                        }
                    />

                    <Route
                        path={`${this.baseUrlPattern}/(finished)`}
                        render={
                            props =>
                                <TabletPage
                                    t={this.t}
                                    i18n={i18n}
                                    api={this.api}
                                    moment={this.moment}
                                    language={this.state.language}
                                    region={this.state.region}
                                    baseUrl={this.baseUrl}
                                    showToast={(data) => { return this.showToast(data) }}
                                    showFinished={true}
                                    {...props}
                                />
                        }
                    />

                    <Route
                        path={`${this.baseUrlPattern}/(student)`}
                        render={
                            props =>
                                <StudentPage
                                    t={this.t}
                                    i18n={i18n}
                                    api={this.api}
                                    moment={this.moment}
                                    language={this.state.language}
                                    region={this.state.region}
                                    baseUrl={this.baseUrl}
                                    showToast={(data) => { return this.showToast(data) }}
                                    {...props}
                                />
                        }
                    />

                    <Route
                        path={`${this.baseUrlPattern}/:section?`}
                        render={
                            props =>
                                <LandingPage
                                    t={this.t}
                                    i18n={i18n}
                                    api={this.api}
                                    moment={this.moment}
                                    language={this.state.language}
                                    region={this.state.region}
                                    baseUrl={this.baseUrl}
                                    showToast={(data) => { return this.showToast(data) }}
                                    {...props}
                                />
                        }
                    />

                    <Route
                        path={`*`}
                        render={
                            props =>
                                <NotFoundPage
                                    t={this.t}
                                    i18n={i18n}
                                    api={this.api}
                                    moment={this.moment}
                                    language={this.state.language}
                                    region={this.state.region}
                                    baseUrl={this.baseUrl}
                                    showToast={(data) => { return this.showToast(data) }}
                                    {...props}
                                />
                        }
                    />

                </Switch>
            </BrowserRouter>
        )

        return (
            <React.Fragment>
                <ToastContainer
                    transition={Slide}
                />
                <LoadingBar
                    height={3}
                    color='#311B92'
                    ref={this.loading_bar}
                />
                {routing}
            </React.Fragment>
        )

    }
}

ReactDOM.render(<Root />, document.getElementById('root'));


