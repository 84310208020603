import React from 'react'
import BaseComponent from '../../../../common/base';
import QuestionnairePartialCard from '../../../partials/card';

export class StepMemorySentences extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = Object.assign(
            this.state
        );
        this.disableContextMenu = this.disableContextMenu.bind(this);
        this.disableCopyPaste = this.disableCopyPaste.bind(this);
    }

    disableContextMenu(evt) {
        evt.preventDefault();
    }

    disableCopyPaste(evt) {
        evt.clipboardData.setData("text/plain", this.t(`questionnaire.memory.sentences.notAllowed`));
        evt.preventDefault();
    }

    componentDidMount() {
        super.componentDidMount();
        document.addEventListener("contextmenu", this.disableContextMenu);
        document.addEventListener("copy", this.disableCopyPaste);
        this.readNext();
    }

    componentWillUnmount() {
        document.removeEventListener("contextmenu", this.disableContextMenu);
        document.removeEventListener("copy", this.disableCopyPaste);
    }

    readNext() {
        setTimeout((self) => {
            self.onSubmit();
        }, 10 * 1000, this);
    }

    onSubmit(e) {
        this.props.nextStep();
    }

    render() {
        return (
            <>
                <QuestionnairePartialCard
                    title={this.t(`questionnaire.memory.sentences.title`)}
                    body={
                        <>
                            <p className="lead" style={{ fontWeight: 'normal', userSelect: 'none' }}>{this.t(`questionnaire.memory.sentences.${this.props.sentence}`)}</p>
                        </>
                    }
                />
            </>
        )
    }

}

export default StepMemorySentences;