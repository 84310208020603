import React from 'react'
import BaseComponent from '../../../common/base';
import { Form, Button, Card } from 'react-bootstrap';
import QuestionnairePartialCard from '../../partials/card';

export class StepGds15 extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = Object.assign(
            this.state,
            {
                index: 0
            }
        )

        this.questions = [
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15"
        ];
    }

    onSubmit(e) {
        const question = this.questions[this.state.index];

        this.props.dbSet(`gds15.q${question}.reactionTime`, this.firstReactionTime());
        this.props.dbSet(`gds15.q${question}.responseTime`, this.sinceMount());
        this.props.dbSet(`gds15.q${question}.answer`, this.state.answer);

        if (this.state.index + 1 < this.questions.length) {

            this.setState({ answer: "" });

            this.props.setStep(
                this.props.stepIndex,
                () => {
                    this.setState(
                        {
                            index: this.state.index + 1
                        },
                        () => {
                            this.resetTimers()
                        }
                    )
                }
            );

        } else {
            this.props.nextStep();
        }
    }

    render() {

        const question = this.questions[this.state.index];

        return (
            <>
                <QuestionnairePartialCard
                    onSubmit={(e) => this.onSubmit(e)}
                    title={this.t(`questionnaire.gds15.title`)}
                    body={
                        <>

                            <Card.Text>
                                {this.t(`questionnaire.gds15.question.${question}.${this.props.gender}`)}
                            </Card.Text>

                            {
                                ['yes', 'no'].map(itm => {
                                    return (
                                        <Form.Group key={`radio-gds15-${itm}`}>
                                            <Form.Check
                                                custom
                                                required
                                                type={'radio'}
                                                name={`gds15`}
                                                value={itm}
                                                id={`radio-gds15-${itm}`}
                                                label={this.t(`questionnaire.gds15.answer.${itm}`)}
                                                checked={this.state.answer === itm}
                                                onChange={(e) => this.onChange('answer', e)}
                                            />
                                        </Form.Group>
                                    )
                                })
                            }
                        </>
                    }
                    footer={
                        <Button type="submit" variant="primary">
                            {this.t(`questionnaire.common.saveAndNextStep`)}
                        </Button>
                    }
                />
            </>
        )
    }

}

export default StepGds15;