import React from 'react'
import BaseComponent from '../../../../common/base';
import { Button } from 'react-bootstrap';
import QuestionnairePartialCard from '../../../partials/card';

export class StepMemoryWordVelvet extends BaseComponent {

    render() {
        return (
            <>
                <QuestionnairePartialCard
                    title={this.t('questionnaire.memory.word.title')}
                    body={
                        <>
                            <p className="display-4">{this.t('questionnaire.memory.word.velvet')}</p>
                        </>
                    }
                    footer={
                        <Button variant="primary" onClick={(e) => this.props.nextStep(e)}>
                            {this.t(`questionnaire.common.rememberAndNextStep`)}
                        </Button>
                    }
                />
            </>
        )
    }

}

export default StepMemoryWordVelvet;