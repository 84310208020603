import React from 'react'
import BaseComponent from '../../../../common/base';
import { Form, Button } from 'react-bootstrap';
import QuestionnairePartialCard from '../../../partials/card';

export class StepMemoryCheckNumbers extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = Object.assign(
            this.state,
            {
                first: "",
                second: "",
                third: "",
                fourth: "",
                fifth: ""
            }
        )

    }

    onSubmit(e) {

        const { first, second, third, fourth, fifth } = this.state;
        const { section } = this.props;
        let items = [];

        if (section === "numbersAsc") {
            items = [
                { ident: "first", correct: parseInt(first, 10) === 2 },
                { ident: "second", correct: parseInt(second, 10) === 1 },
                { ident: "third", correct: parseInt(third, 10) === 8 },
                { ident: "fourth", correct: parseInt(fourth, 10) === 5 },
                { ident: "fifth", correct: parseInt(fifth, 10) === 4 },
            ];
        } else if (section === "numbersDesc") {
            items = [
                { ident: "first", correct: parseInt(first, 10) === 2 },
                { ident: "second", correct: parseInt(second, 10) === 4 },
                { ident: "third", correct: parseInt(third, 10) === 7 }
            ];
        }

        const errorCount = items.filter(itm => itm.correct === false).length;

        this.props.dbSet(`memory.${section}.reactionTime`, this.firstReactionTime());
        this.props.dbSet(`memory.${section}.responseTime`, this.sinceMount());
        this.props.dbSet(`memory.${section}.errorCount`, errorCount);
        this.props.dbSet(`memory.${section}.answers`, (section === "numbersAsc") ? [first, second, third, fourth, fifth] : [first, second, third]);
        this.props.nextStep();
    }

    render() {

        let items = []
        if (this.props.section === "numbersAsc") {
            items = [
                { ident: "first" },
                { ident: "second" },
                { ident: "third" },
                { ident: "fourth" },
                { ident: "fifth" },
            ];
        } else if (this.props.section === "numbersDesc") {
            items = [
                { ident: "first" },
                { ident: "second" },
                { ident: "third" }
            ];
        }

        return (
            <>
                <QuestionnairePartialCard
                    onSubmit={(e) => this.onSubmit(e)}
                    title={this.t(`questionnaire.memory.check.${this.props.section}.title`)}
                    body={
                        <>
                            {items.map((itm) => {
                                return (
                                    <Form.Group key={itm.ident}>
                                        <Form.Control
                                            type="number"
                                            placeholder={`...`}
                                            value={this.state[itm.ident]}
                                            onChange={
                                                (e) => {
                                                    this.measureReaction();
                                                    this.setState({ [itm.ident]: e.target.value })
                                                }
                                            }
                                        />
                                    </Form.Group>
                                )
                            })}

                        </>
                    }
                    footer={
                        <Button type="submit" variant="primary">
                            {this.t('questionnaire.common.saveAndNextStep')}
                        </Button>
                    }
                />
            </>
        )
    }

}

export default StepMemoryCheckNumbers;