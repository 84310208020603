import React from 'react'
import BaseComponent from '../../../common/base';
import { Card, Button } from 'react-bootstrap';
import QuestionnairePartialCard from '../../partials/card';
import coverPhoto from '../../../../assets/images/alarm_clock3x.png'

export class StepOrientationDone extends BaseComponent {

    render() {
        return (
            <>
                <QuestionnairePartialCard
                    img={coverPhoto}
                    title={this.t('questionnaire.orientation.done.title')}
                    body={
                        <>
                            <Card.Text>
                                {this.t(`questionnaire.orientation.done.text.${this.props.gender}`)}
                            </Card.Text>
                            <Button variant="primary" onClick={(e) => this.props.nextStep(e)}>
                                {this.t(`questionnaire.common.nextStep`)}
                            </Button>
                        </>
                    }
                />
            </>
        )
    }

}

export default StepOrientationDone;