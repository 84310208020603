import React from 'react'
import BaseComponent from '../../../common/base';
import { Card, Button } from 'react-bootstrap';
import QuestionnairePartialCard from '../../partials/card';
import coverPhoto from '../../../../assets/images/products3x.png'

export class StepAbstractionIntroduction extends BaseComponent {

    render() {
        return (
            <>
                <QuestionnairePartialCard
                    img={coverPhoto}
                    title={this.t('questionnaire.abstraction.introduction.title')}
                    body={
                        <>
                            <Card.Text>
                                {this.t('questionnaire.abstraction.introduction.text.01')}
                            </Card.Text>

                            <Card.Text>
                                {this.t('questionnaire.abstraction.introduction.text.02')}
                                <br />
                                {this.t('questionnaire.abstraction.introduction.text.03')}
                                {' '}
                                <strong>
                                    {this.t('questionnaire.abstraction.introduction.text.04')}
                                </strong>.
                            </Card.Text>

                            <Button variant="primary" onClick={(e) => this.props.nextStep(e)}>
                                {this.t(`questionnaire.common.startTask`)}
                            </Button>
                        </>
                    }
                />
            </>
        )
    }

}

export default StepAbstractionIntroduction;