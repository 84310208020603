import React from 'react'
import BaseComponent from '../../common/base';
import { Navbar, Nav } from 'react-bootstrap';
import imgLogo from '../../../assets/images/icon_256x256.png'

export class Navigation extends BaseComponent {

    render() {
        return (
            <Navbar expand="lg" className="p-0 pt-4 pb-4">
                <Navbar.Brand
                    href={this.props.baseUrl + '#hero'}
                    onClick={(e) => { this.props.scrollToSection(this.t('landing.navbar.link.section_hero.href')) }}
                >
                    <img
                        alt=""
                        src={imgLogo}
                        className="logo d-inline-block align-top"
                    />
                    &nbsp;
                    {this.t('landing.navbar.title')}
                    <span className="d-none d-sm-none d-md-none d-lg-none d-xl-inline">
                        {this.t('landing.navbar.slogan')}
                    </span>
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse>
                    <Nav className="mr-auto">
                        &nbsp;
                    </Nav>
                    <Nav>
                        <Nav.Link
                            href={this.props.baseUrl + '#01'}
                            onClick={(e) => { this.props.scrollToSection(this.t('landing.navbar.link.section_01.href')) }}
                        >
                            {this.t('landing.navbar.link.section_01.text')}
                        </Nav.Link>
                        {/*
                        <Nav.Link
                            href={this.props.baseUrl + '#02'}
                            onClick={(e) => { this.props.scrollToSection(this.t('landing.navbar.link.section_02.href')) }}
                        >
                            {this.t('landing.navbar.link.section_02.text')}
                        </Nav.Link>
                        */}
                        <Nav.Link
                            href={this.props.baseUrl + '#03'}
                            onClick={(e) => { this.props.scrollToSection(this.t('landing.navbar.link.section_03.href')) }}
                        >
                            {this.t('landing.navbar.link.section_03.text')}
                        </Nav.Link>
                        {
                            /*
                                <Nav.Link
                                    href={this.props.baseUrl + '#04'}
                                    onClick={(e) => { this.props.scrollToSection(this.t('landing.navbar.link.section_04.href')) }}
                                >
                                    {this.t('landing.navbar.link.section_04.text')}
                                </Nav.Link>
                        
                                <Nav.Link
                                    className="btn btn-purple"
                                    style={{ color: '#fff' }}
                                    href={this.props.baseUrl + '#cta'}
                                    onClick={(e) => { this.props.scrollToSection(this.t('landing.navbar.link.section_cta.href')) }}
                                >
                                    {this.t('landing.navbar.link.section_cta.text')}
                                </Nav.Link>
                            */
                        }
                    </Nav>
                </Navbar.Collapse>
            </Navbar >
        )
    }
}

export default Navigation
