import React from 'react'
import BaseComponent from '../common/base';
import { Container, Row, Col, Navbar, Card, Button } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import Footer from './partials/footer';
import SubscribeTablet from './partials/subscribe-tablet';
import imgLogo from '../../assets/images/icon_256x256.png'

export class TabletPage extends BaseComponent {

    render() {
        return (
            <>
                <Helmet>
                    <title>{this.t('landing.helmet.title')}</title>
                    <meta name="description" content={this.t('landing.helmet.description')} />
                </Helmet>

                <Container className="landing-page pb-5">

                    <Row
                        id={this.t('landing.navbar.link.section_navbar.href')}
                        ref={this.section_navbar}
                    >
                        <Col>
                            <Navbar expand="lg" className="p-0 pt-4 pb-4">
                                <Navbar.Brand href='/us/en/tablet'>
                                    <img
                                        alt=""
                                        src={imgLogo}
                                        className="logo d-inline-block align-top"
                                    />
                                    &nbsp;
                                    {this.t('landing.navbar.title')}
                                    <span className="d-none d-sm-none d-md-none d-lg-none d-xl-inline">
                                        {this.t('landing.navbar.slogan')}
                                    </span>
                                </Navbar.Brand>
                            </Navbar>
                        </Col>
                    </Row>

                    {
                        this.props.showFinished ? (
                            <Row>
                                <Col>
                                    <Card className="card-enrol">
                                        <Card.Header>
                                            {this.t('landing.tablet.thanks.header')}
                                        </Card.Header>
                                        <Card.Body>
                                            <h2 className='text-center py-5'>{this.t('landing.tablet.thanks.thankYou')}</h2>
                                        </Card.Body>
                                        <Card.Footer className="text-muted text-center" style={{ fontSize: "0.8rem" }}>
                                            <div className="text-center">
                                                <Button
                                                    variant="purple"
                                                    size="lg"
                                                    className="pl-5 pr-5 pt-2 pb-2"
                                                    onClick={(e) => {
                                                        window.location.replace(`${process.env.REACT_APP_URL}/${this.props.region}/${this.props.language}/tablet`);
                                                    }}>
                                                    {this.t('landing.tablet.thanks.next')}
                                                </Button>
                                            </div>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            </Row>
                        ) : (
                            <Row>
                                <Col>
                                    <SubscribeTablet {...this.props} />
                                </Col>
                            </Row >
                        )
                    }


                    <Row>
                        <Col>
                            <Footer {...this.props} link={'tablet'} />
                        </Col>
                    </Row>

                </Container>
            </>
        )
    }
}

export default TabletPage
