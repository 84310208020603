import React from 'react'
import BaseComponent from '../../../common/base';
import { Card, Form, Button } from 'react-bootstrap';
import QuestionnairePartialCard from '../../partials/card';

export class StepOrientationMonthDay extends BaseComponent {

    onSubmit(e) {
        this.props.dbSet('orientation.monthDay.reactionTime', this.firstReactionTime());
        this.props.dbSet('orientation.monthDay.responseTime', this.sinceMount());
        this.props.dbSet('orientation.monthDay.answer', this.normalizeWord(this.state.answer));
        this.props.dbSet(
            'orientation.monthDay.isCorrect',
            parseInt(this.normalizeWord(this.state.answer), 10) === new Date().getDate()
        );
        this.props.nextStep();
    }

    render() {
        return (
            <>
                <QuestionnairePartialCard
                    onSubmit={(e) => this.onSubmit(e)}
                    title={this.t('questionnaire.orientation.monthDay.title')}
                    body={
                        <>
                            <Card.Text>
                                {this.t(`questionnaire.orientation.monthDay.question.${this.props.gender}`)}
                            </Card.Text>

                            <Form.Group>
                                <Form.Control
                                    as="select"
                                    required
                                    value={this.state.answer}
                                    onChange={(e) => this.onChange('answer', e)}
                                >
                                    <option value="">{this.t('questionnaire.orientation.monthDay.placeholder')}</option>
                                    {
                                        Array
                                            .from({ length: 31 }, (v, i) => i + 1)
                                            .map(
                                                itm => {
                                                    return <option value={itm} key={itm}>{itm}</option>
                                                }
                                            )
                                    }
                                </Form.Control>
                            </Form.Group>
                        </>
                    }
                    footer={
                        <Button type="submit" variant="primary">
                            {this.t(`questionnaire.common.saveAndNextStep`)}
                        </Button>
                    }
                />
            </>
        )
    }

}

export default StepOrientationMonthDay;