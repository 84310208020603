import React from 'react'
import BaseComponent from '../../../common/base';
import { Card, Form, Button } from 'react-bootstrap';
import QuestionnairePartialCard from '../../partials/card';

export class StepCountingFourth extends BaseComponent {

    onSubmit(e) {
        this.props.dbSet('counting.fourth.reactionTime', this.firstReactionTime());
        this.props.dbSet('counting.fourth.responseTime', this.sinceMount());
        this.props.dbSet('counting.fourth.answer', parseInt(this.state.answer, 10));
        this.props.dbSet('counting.fourth.isCorrect', parseInt(this.props.dbGet('counting.third.answer'), 10) - parseInt(this.state.answer, 10) === 7);
        this.props.nextStep();
    }

    render() {
        return (
            <>
                <QuestionnairePartialCard
                    onSubmit={(e) => this.onSubmit(e)}
                    title={this.t('questionnaire.counting.operation.04.title')}
                    body={
                        <>

                            <Card.Text>
                                <strong>{this.t('questionnaire.counting.operation.04.question.01')}</strong>
                            </Card.Text>

                            <Card.Text>
                                {this.t('questionnaire.counting.operation.04.question.02')}
                            </Card.Text>

                            <Form.Group>
                                <Form.Control
                                    required
                                    type="number"
                                    placeholder={this.t(`questionnaire.common.typeResult`)}
                                    value={this.state.answer}
                                    onChange={(e) => this.onChange('answer', e)}
                                />
                            </Form.Group>
                        </>
                    }
                    footer={
                        <Button type="submit" variant="primary">
                            {this.t(`questionnaire.common.saveAndNextStep`)}
                        </Button>
                    }
                />
            </>
        )
    }

}

export default StepCountingFourth;