import React from 'react'
import BaseComponent from '../../../common/base';
import { Card, Button } from 'react-bootstrap';
import QuestionnairePartialCard from '../../partials/card';
import coverPhoto from '../../../../assets/images/magnifier3x.png'

export class StepAttentionIntroduction extends BaseComponent {

    render() {
        return (
            <>
                <QuestionnairePartialCard
                    img={coverPhoto}
                    title={this.t(`questionnaire.attention.introduction.title`)}
                    body={
                        <>
                            <Card.Text>
                                {this.t(`questionnaire.attention.introduction.question.01.${this.props.gender}`)}
                            </Card.Text>

                            <Card.Text>
                                <strong>
                                    {this.t(`questionnaire.attention.introduction.question.02.${this.props.gender}`)}
                                </strong>
                            </Card.Text>

                            <Card.Text>
                                {this.t(`questionnaire.attention.introduction.question.03.${this.props.gender}`)}
                            </Card.Text>

                            <Button variant="primary" onClick={(e) => this.props.nextStep(e)}>
                                {this.t(`questionnaire.common.startTask`)}
                            </Button>
                        </>
                    }
                />
            </>
        )
    }

}

export default StepAttentionIntroduction;