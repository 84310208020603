import React from 'react'
import BaseComponent from '../../../common/base';
import { Card, Form, Button } from 'react-bootstrap';
import QuestionnairePartialCard from '../../partials/card';
import flat from 'solstices-and-equinoxes';

export class StepOrientationSeason extends BaseComponent {

    constructor(props) {
        super(props);
        this.currentSeason = null;
        this.determineSeason();
    }

    determineSeason() {
        const dataForThisYear = flat.filter(itm => itm.year === new Date().getFullYear())[0];
        const spring = new Date(dataForThisYear.spring);
        const summer = new Date(dataForThisYear.summer);
        const fall = new Date(dataForThisYear.fall);
        const winter = new Date(dataForThisYear.winter);
        const currentDate = new Date();
        if (currentDate < spring) {
            this.currentSeason = "winter";
        } else if (currentDate < summer) {
            this.currentSeason = "spring";
        } else if (currentDate < fall) {
            this.currentSeason = "summer";
        } else if (currentDate < winter) {
            this.currentSeason = "fall";
        } else {
            this.currentSeason = "winter";
        }
    }

    onSubmit(e) {
        this.props.dbSet('orientation.season.reactionTime', this.firstReactionTime());
        this.props.dbSet('orientation.season.responseTime', this.sinceMount());
        this.props.dbSet('orientation.season.answer', this.normalizeWord(this.state.answer));
        this.props.dbSet('orientation.season.isCorrect', this.normalizeWord(this.state.answer) === this.currentSeason);
        this.props.nextStep();
    }

    render() {
        return (
            <>
                <QuestionnairePartialCard
                    onSubmit={(e) => this.onSubmit(e)}
                    title={this.t('questionnaire.orientation.season.title')}
                    body={
                        <>
                            <Card.Text>
                                {this.t(`questionnaire.orientation.season.question.${this.props.gender}`)}
                            </Card.Text>

                            <Form.Group>
                                <Form.Control
                                    as="select"
                                    required
                                    value={this.state.answer}
                                    onChange={(e) => this.onChange('answer', e)}
                                >
                                    <option value="">{this.t('questionnaire.orientation.season.placeholder')}</option>
                                    {
                                        ['spring', 'summer', 'fall', 'winter'].map((itm) => {
                                            return (
                                                <option
                                                    key={itm}
                                                    value={itm}
                                                >
                                                    {this.t(`questionnaire.orientation.season.option.${itm}`)}
                                                </option>
                                            );
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                        </>
                    }
                    footer={
                        <Button type="submit" variant="primary">
                            {this.t(`questionnaire.common.saveAndNextStep`)}
                        </Button>
                    }
                />
            </>
        )
    }

}

export default StepOrientationSeason;