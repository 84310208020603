import React from 'react'
import { Container, Row, Button, Modal, Form } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import { X } from "react-feather";
import BaseComponent from '../common/base';
import Database from "../common/database";
import fsc from '../common/fullscreen';

import StepPreparationLoading from "./steps/preparation/loading";
import StepPreparationWelcome from "./steps/preparation/welcome";
import StepPreparationConsent from "./steps/preparation/consent";
// import StepPreparationGeolocation from "./steps/preparation/geolocation";

// import StepPreparationCamera from "./steps/preparation/camera";
// import StepPreparationSound from "./steps/preparation/sound";
// import StepPreparationVoice from "./steps/preparation/voice";

// import StepPreparationFullscreen from "./steps/preparation/fullscreen";
import StepPreparationDone from "./steps/preparation/done";

import StepHealthOverall from "./steps/health/overall";
import StepHealthIllness from "./steps/health/illness";
import StepHealthFitness from "./steps/health/fitness";
import StepHealthFocus from "./steps/health/focus";

import StepPdq8 from "./steps/pdq8";
// import StepQuipRs from "./steps/quiprs";
import StepGds15 from "./steps/gds15";

// import StepMoodSadness from "./steps/mood/sadness";
// import StepMoodFuture from "./steps/mood/future";
// import StepMoodSatisfaction from "./steps/mood/satisfaction";
// import StepMoodDisappointment from "./steps/mood/disappointment";
// import StepMoodDone from "./steps/mood/done";

import StepTrailIntroductionA from "./steps/trail/introductionA";
import StepTrailA from "./steps/trail/trailA";
import StepTrailIntroductionB from "./steps/trail/introductionB";
import StepTrailB from "./steps/trail/trailB";
import StepTrailIntroductionBShort from "./steps/trail/introductionBShort";
import StepTrailBShort from "./steps/trail/trailBShort";
import StepTrailDone from "./steps/trail/done";

import StepDrawCube from "./steps/draw/cube";
import StepDrawClock from "./steps/draw/clock";
import StepDrawDone from "./steps/draw/done";

import StepNamingCow from "./steps/naming/cow";
import StepNamingLion from "./steps/naming/lion";
import StepNamingHorse from "./steps/naming/horse";
import StepNamingDone from "./steps/naming/done";

import StepMemoryIntroduction from "./steps/memory/introduction";
import StepMemoryWordFace from "./steps/memory/word/face";
import StepMemoryWordVelvet from "./steps/memory/word/velvet";
import StepMemoryWordChurch from "./steps/memory/word/church";
import StepMemoryWordDaisy from "./steps/memory/word/daisy";
import StepMemoryWordRed from "./steps/memory/word/red";
import StepMemoryCheckFirst from "./steps/memory/check/first";
import StepMemoryCheckSecond from "./steps/memory/check/second";
import StepMemoryDone from "./steps/memory/done";

import StepMemoryNumbers from "./steps/memory/numbers";
import StepMemoryCheckNumbers from "./steps/memory/check/numbers";

import StepMemorySentences from './steps/memory/sentences';
import StepMemoryCheckSentences from './steps/memory/check/sentences';

import StepAttentionIntroduction from "./steps/attention/introduction";
import StepAttentionLetters from "./steps/attention/letters";
import StepAttentionDone from "./steps/attention/done";

import StepCountingIntroduction from "./steps/counting/introduction";
import StepCountingFirst from "./steps/counting/first";
import StepCountingSecond from "./steps/counting/second";
import StepCountingThird from "./steps/counting/third";
import StepCountingFourth from "./steps/counting/fourth";
import StepCountingFifth from "./steps/counting/fifth";

// import StepFluencySpeech from "./steps/fluency/speech";

import StepFluencyTyping from "./steps/fluency/typing";
import StepFluencyDone from "./steps/fluency/done";

import StepAbstractionIntroduction from "./steps/abstraction/introduction";
import StepAbstractionTransportation from "./steps/abstraction/transportation";
import StepAbstractionMeasurement from "./steps/abstraction/measurement";

import StepStroopIntroduction from "./steps/stroop/introduction";
import StepStroopWords from "./steps/stroop/words";
import StepStroopDone from "./steps/stroop/done";

import StepOrientationYear from "./steps/orientation/year";
import StepOrientationSeason from "./steps/orientation/season";
import StepOrientationMonth from "./steps/orientation/month";
import StepOrientationMonthDay from "./steps/orientation/monthDay";
import StepOrientationWeekDay from "./steps/orientation/weekDay";
import StepOrientationCountry from "./steps/orientation/country";
import StepOrientationCity from "./steps/orientation/city";
import StepOrientationPlace from "./steps/orientation/place";
import StepOrientationDone from "./steps/orientation/done";

import StepSleepGeneral from "./steps/sleep/general";
import StepSleepEpworthIntroduction from "./steps/sleep/epworth/introduction";
import StepSleepEpworthTest from "./steps/sleep/epworth/test";
import StepSleepDone from "./steps/sleep/done";

import StepEmotionsNamingHappinessFirst from "./steps/emotions/naming/happinessFirst";
import StepEmotionsNamingAnger from "./steps/emotions/naming/anger";
import StepEmotionsNamingSadness from "./steps/emotions/naming/sadness";
import StepEmotionsNamingHappinessSecond from "./steps/emotions/naming/happinessSecond";
import StepEmotionsNamingDisgust from "./steps/emotions/naming/disgust";
import StepEmotionsNamingSurprise from "./steps/emotions/naming/surprise";
import StepEmotionsNamingFear from "./steps/emotions/naming/fear";

// import StepEmotionsExpressionIntroduction from "./steps/emotions/expression/introduction";
// import StepEmotionsExpressionRecording from "./steps/emotions/expression/recording";

import StepThankYouPage from "./steps/thanks/thankYou";

import StepCsatQuestionnaire from "./steps/csat/questionnaire";
import StepCsatThankYou from "./steps/csat/thankYou";

export class Questionnaire01Page extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = Object.assign(
            this.state,
            {
                stepIndex: 0,
                gender: "m",
                isInFullScreen: false,
                isTablet: false,
                showModalExitTablet: false,
                fader: "in",
                progress: 0,
                version: "01"
            }
        );

        /**
          *  GDS-15
          *  MoCa
          *  Epworth
          *  TrailA&B
          *  Stroop
          *  FE
          *  PDQ8
        */

        this.steps = [

            { "view": StepPreparationLoading, "conditions": [] },
            process.env.REACT_APP_CONSENT_REQUIRED !== "false" ? { "view": StepPreparationConsent, "conditions": [] } : null,
            { "view": StepPreparationWelcome, "conditions": [] },
            // { "view": StepPreparationGeolocation, "conditions": [] },

            // { "view": StepPreparationCamera, "conditions": [["consent.toImageProcessing", "===", true]] },
            // { "view": StepPreparationSound, "conditions": [] },
            // { "view": StepPreparationVoice, "conditions": [["consent.toVoiceProcessing", "===", true]] },

            // { "view": StepPreparationFullscreen, "conditions": [] },
            { "view": StepPreparationDone, "conditions": [] },

            { "view": StepPdq8, "conditions": [] },
            // { "view": StepQuipRs, "conditions": [] },

            { "view": StepGds15, "conditions": [] },

            { "view": StepHealthOverall, "conditions": [] },
            { "view": StepHealthIllness, "conditions": [["health.overall.answer", "in", ['moderate', 'bad']]] },
            { "view": StepHealthFitness, "conditions": [] },
            { "view": StepHealthFocus, "conditions": [] },

            { "view": StepTrailIntroductionA, "conditions": [] },
            { "view": StepTrailA, "conditions": [] },
            { "view": StepTrailIntroductionBShort, "conditions": [] },
            { "view": StepTrailBShort, "conditions": [] },
            { "view": StepTrailIntroductionB, "conditions": [] },
            { "view": StepTrailB, "conditions": [] },
            { "view": StepTrailDone, "conditions": [] },

            { "view": StepDrawCube, "conditions": [] },
            { "view": StepDrawClock, "conditions": [] },
            { "view": StepDrawDone, "conditions": [] },

            { "view": StepNamingCow, "conditions": [] },
            { "view": StepNamingLion, "conditions": [] },
            { "view": StepNamingHorse, "conditions": [] },
            { "view": StepNamingDone, "conditions": [] },

            { "view": StepMemoryIntroduction, "props": { section: "letters" }, "conditions": [] },
            { "view": StepMemoryWordFace, "conditions": [] },
            { "view": StepMemoryWordVelvet, "conditions": [] },
            { "view": StepMemoryWordChurch, "conditions": [] },
            { "view": StepMemoryWordDaisy, "conditions": [] },
            { "view": StepMemoryWordRed, "conditions": [] },
            { "view": StepMemoryCheckFirst, "conditions": [] },

            { "view": StepMemoryIntroduction, "props": { section: "numbersAsc" }, "conditions": [] },
            { "view": StepMemoryNumbers, "props": { section: "numbersAsc" }, "conditions": [] },
            { "view": StepMemoryCheckNumbers, "props": { section: "numbersAsc" }, "conditions": [] },

            { "view": StepMemoryIntroduction, "props": { section: "numbersDesc" }, "conditions": [] },
            { "view": StepMemoryNumbers, "props": { section: "numbersDesc" }, "conditions": [] },
            { "view": StepMemoryCheckNumbers, "props": { section: "numbersDesc" }, "conditions": [] },

            { "view": StepMemoryIntroduction, "props": { section: "sentences" }, "conditions": [] },
            { "view": StepMemorySentences, "props": { sentence: "sentenceFirst" }, "conditions": [] },
            { "view": StepMemoryCheckSentences, "props": { section: "sentenceFirst" }, "conditions": [] },
            { "view": StepMemorySentences, "props": { sentence: "sentenceSecond" }, "conditions": [] },
            { "view": StepMemoryCheckSentences, "props": { section: "sentenceSecond" }, "conditions": [] },

            { "view": StepAttentionIntroduction, "conditions": [] },
            { "view": StepAttentionLetters, "conditions": [] },
            { "view": StepAttentionDone, "conditions": [] },

            { "view": StepCountingIntroduction, "conditions": [] },
            { "view": StepCountingFirst, "conditions": [] },
            { "view": StepCountingSecond, "conditions": [] },
            { "view": StepCountingThird, "conditions": [] },
            { "view": StepCountingFourth, "conditions": [] },
            { "view": StepCountingFifth, "conditions": [] },

            // { "view": StepFluencySpeech, "conditions": [["microphone.isWorking", "===", true]] },

            { "view": StepFluencyTyping, "conditions": [] },
            { "view": StepFluencyDone, "conditions": [] },

            { "view": StepStroopIntroduction, "conditions": [] },
            { "view": StepStroopWords, "conditions": [] },
            { "view": StepStroopDone, "conditions": [] },

            { "view": StepAbstractionIntroduction, "conditions": [] },
            { "view": StepAbstractionTransportation, "conditions": [] },
            { "view": StepAbstractionMeasurement, "conditions": [] },

            { "view": StepMemoryCheckSecond, "conditions": [] },
            { "view": StepMemoryDone, "conditions": [] },

            { "view": StepOrientationYear, "conditions": [] },
            { "view": StepOrientationSeason, "conditions": [] },
            { "view": StepOrientationMonth, "conditions": [] },
            { "view": StepOrientationMonthDay, "conditions": [] },
            { "view": StepOrientationWeekDay, "conditions": [] },
            { "view": StepOrientationCountry, "conditions": [] },
            { "view": StepOrientationCity, "conditions": [] },
            { "view": StepOrientationPlace, "conditions": [] },
            { "view": StepOrientationDone, "conditions": [] },

            { "view": StepSleepGeneral, "conditions": [] },
            { "view": StepSleepEpworthIntroduction, "conditions": [] },
            { "view": StepSleepEpworthTest, "conditions": [] },
            { "view": StepSleepDone, "conditions": [] },

            { "view": StepEmotionsNamingHappinessFirst, "conditions": [] },
            { "view": StepEmotionsNamingAnger, "conditions": [] },
            { "view": StepEmotionsNamingSadness, "conditions": [] },
            { "view": StepEmotionsNamingHappinessSecond, "conditions": [] },
            { "view": StepEmotionsNamingDisgust, "conditions": [] },
            { "view": StepEmotionsNamingSurprise, "conditions": [] },
            { "view": StepEmotionsNamingFear, "conditions": [] },

            // { "view": StepEmotionsExpressionIntroduction, "conditions": [["consent.toImageProcessing", "===", true, 2], ["camera.isWorking", "===", true, 2]] },
            // { "view": StepEmotionsExpressionRecording, "conditions": [] },

            { "view": StepThankYouPage, "conditions": [] },

            { "view": StepCsatQuestionnaire, "conditions": [] },
            { "view": StepCsatThankYou, "conditions": [] },
        ].filter(Boolean);

        this.handleFullScreen = this.handleFullScreen.bind(this);
        this.database = new Database();
    }

    componentDidMount() {
        super.componentDidMount();
        window.addEventListener("APP_FULLSCREEN", this.handleFullScreen, false);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("APP_FULLSCREEN", this.handleFullScreen, false);
    }

    handleFullScreen(data) {
        let isInFullScreen = data.detail ? true : false;
        this.setState({ isInFullScreen: isInFullScreen });
        this.dbSet('fullscreen.isWorking', isInFullScreen);
    }

    nextStep(e) {
        if (e) {
            e.preventDefault();
        }

        let nextStep = this.state.stepIndex + 1;
        if (nextStep >= this.steps.length) {
            return;
        }

        let fulfilled = 1;
        let rewindSteps = 1;
        if (this.steps[nextStep].conditions.length > 0) {
            this.steps[nextStep].conditions.forEach(condition => {
                const section = condition[0];
                const operator = condition[1];
                const value = condition[2];
                rewindSteps = condition[3] || 1;
                switch (operator) {
                    case "in":
                        fulfilled = fulfilled * (value.includes(this.dbGet(section)));
                        break;
                    case "===":
                    default:
                        fulfilled = fulfilled * (this.dbGet(section) === value);
                        break;
                }
            });
        }

        if (fulfilled) {
            this.setStep(nextStep);
        } else {
            this.setStep(nextStep + rewindSteps);
        }
    }

    rewindSteps(e, value) {
        if (e) {
            e.preventDefault();
        }
        let nextStep = this.state.stepIndex + value;
        if (nextStep >= this.steps.length) {
            return;
        }
        if (nextStep < 0) {
            return;
        }
        this.setStep(nextStep);
    }

    setStep(stepIndex, callback) {
        let self = this;
        this.setState({
            fader: "out"
        }, () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            let progress = Math.min(100, Math.round(stepIndex * 100 / (self.steps.length - 3)));
            self.dbSet('step', stepIndex);
            self.dbSet('progress', progress);
            if (progress === 100) {
                self.props.showToast({ type: 'info', txt: this.t('questionnaire.common.saving') });
            }
            self.saveQuestionnaire(
                () => {
                    if (progress === 100) {
                        self.props.showToast({ type: 'success', txt: this.t('questionnaire.common.saved') });
                    }
                    self.setState({
                        stepIndex: stepIndex,
                        progress: progress
                    }, () => {
                        self.setState({
                            fader: "in"
                        }, () => {
                            if (callback) {
                                callback()
                            }
                        });
                    });
                },
                (details) => {
                    self.props.showToast({ type: 'error', txt: details.data.error });
                }
            )
        })
    }

    dbAssign(obj) {
        return this.database.assign(obj);
    }

    dbSet(key, value) {
        return this.database.set(key, value);
    }

    dbGet(key, defaultValue) {
        return this.database.get(key, defaultValue);
    }

    setGender(gender) {
        this.setState({ gender: gender });
    }

    setIsTablet(isTablet) {
        this.setState({ isTablet: isTablet });
    }

    saveQuestionnaire(callback, error) {
        let self = this;
        self.setState({ status: 'data' });
        self.api.request(
            `/participant/questionnaire/set?language=${self.props.language}&region=${self.props.region}`,
            {
                'questionnaire': {
                    'uuid': self.props.match.params.uuid,
                    'payload': self.database.get()
                }
            },
            (data) => {
                callback(data);
            },
            (details) => {
                error(details);
            }
        );
    }

    render() {
        const modal = (
            <Modal
                size="md"
                centered
                show={this.state.showModalExitTablet}
                onHide={() => this.setState({ showModalExitTablet: false })}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
                    <p>Do you want to finish this study?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant='danger'
                        onClick={
                            (e) => {
                                let baseUrl = `${process.env.REACT_APP_URL}/${this.props.region}/${this.props.language}/finished`;
                                window.location.replace(baseUrl);
                            }
                        }
                    >
                        YES
                    </Button>
                    <Button
                        onClick={
                            (e) =>
                                this.setState(
                                    {
                                        showModalExitTablet: false
                                    }
                                )
                        }
                    >
                        NO
                    </Button>
                </Modal.Footer>
            </Modal>
        )

        const CurrentView = this.steps[this.state.stepIndex].view;
        const currentViewProps = this.steps[this.state.stepIndex].props || {};
        return (
            <>
                <Helmet>
                    <title>{this.t('questionnaire.helmet.title')}</title>
                    <meta name="description" content={this.t('questionnaire.helmet.description')} />
                </Helmet>

                {modal}

                {
                    this.state.isInFullScreen &&
                    (
                        <Button
                            style={{ position: 'absolute', top: 0, right: 0 }}
                            variant="link"
                            onClick={(e) => { fsc.closeFullscreen(); }}
                        >
                            CLOSE FULL SCREEN<X />
                        </Button>
                    )
                }
                {
                    this.state.isTablet && !this.state.isInFullScreen &&
                    (
                        <Button
                            style={{ position: 'absolute', top: 0, right: 0 }}
                            variant="link"
                            onClick={(e) => { this.setState({ showModalExitTablet: true }) }}
                        >
                            FINISH STUDY<X />
                        </Button>
                    )
                }

                <div
                    style={{ position: 'absolute', top: 0, left: 0, padding: 15 }}
                >
                    {this.t('questionnaire.common.progress')}:
                    {` `}
                    {this.state.progress}
                    %
                </div>

                <Container fluid className="questionnaire-page min-100 bg-light">
                    <Row className="min-100 justify-content-center align-items-center">
                        <div className={`fade ${this.state.fader}`}>
                            <CurrentView
                                setGender={(k) => this.setGender(k)}
                                setIsTablet={(k) => this.setIsTablet(k)}
                                nextStep={(e) => this.nextStep(e)}
                                rewindSteps={(e, v) => this.rewindSteps(e, v)}
                                setStep={(e, c) => this.setStep(e, c)}
                                stepIndex={this.state.stepIndex}
                                gender={this.state.gender}
                                isTablet={this.state.isTablet}
                                dbSet={(k, v) => this.dbSet(k, v)}
                                dbGet={(k, v) => this.dbGet(k, v)}
                                dbAssign={(k) => this.dbAssign(k)}
                                {...this.props}
                                {...currentViewProps}
                            />
                        </div>
                    </Row>
                </Container>
            </>
        )
    }
}

export default Questionnaire01Page
