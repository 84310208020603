import React from 'react'
import BaseComponent from '../../../common/base';
import { Card, Button } from 'react-bootstrap';
import QuestionnairePartialCard from '../../partials/card';
import howToGifPL from '../../../../data/translation/pl/stroop.gif';
import howToGifEN from '../../../../data/translation/en/stroop.gif';

export class StepStroopIntroduction extends BaseComponent {

    constructor(props) {
        super(props);
        this.howToGif = {
            "pl": howToGifPL,
            "en": howToGifEN
        }
    }
    render() {
        return (
            <>
                <QuestionnairePartialCard
                    title={this.t('questionnaire.stroop.introduction.title')}
                    body={
                        <>
                            <Card.Text>
                                {this.t(`questionnaire.stroop.introduction.text.01`)}
                            </Card.Text>

                            <Card.Text>
                                {this.t(`questionnaire.stroop.introduction.text.02`)}
                            </Card.Text>

                            <div className="text-center bg-light pt-3 pb-3 mb-3">
                                <h3>{this.t(`questionnaire.common.example`)}:</h3>
                                <img src={this.howToGif[this.props.language]} alt="" className="img-fluid" />
                            </div>

                            <Button variant="primary" onClick={(e) => this.props.nextStep(e)}>
                                {this.t(`questionnaire.common.startTask`)}
                            </Button>
                        </>
                    }
                />
            </>
        )
    }

}

export default StepStroopIntroduction;