import React from 'react'
import BaseComponent from '../../../common/base';
import PartialTrailEngine from './partials/engine';

export class StepTrailBShort extends BaseComponent {

    constructor(props) {
        super(props);
        this.buttons = [
            { id: this.t(`questionnaire.char.1`) },
            { id: this.t(`questionnaire.char.A`) },
            { id: this.t(`questionnaire.char.2`) },
            { id: this.t(`questionnaire.char.B`) },
            { id: this.t(`questionnaire.char.3`) },
            { id: this.t(`questionnaire.char.C`) },
            { id: this.t(`questionnaire.char.4`) },
            { id: this.t(`questionnaire.char.D`) },
            { id: this.t(`questionnaire.char.5`) },
            { id: this.t(`questionnaire.char.E`) },
        ]
    }

    render() {
        return (
            <>
                <PartialTrailEngine
                    ident="BShort"
                    buttons={this.buttons}
                    title={this.t(`questionnaire.trail.B.task.title`)}
                    {...this.props}
                />
            </>
        )
    }

}

export default StepTrailBShort;