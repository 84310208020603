import React from 'react'
import BaseComponent from '../../../common/base';
import { Card, Button, Form } from 'react-bootstrap';
import QuestionnairePartialCard from '../../partials/card';
import coverPhoto from '../../../../assets/images/horse.jpg'

export class StepNamingHorse extends BaseComponent {

    onSubmit(e) {
        this.props.dbSet('naming.horse.reactionTime', this.firstReactionTime());
        this.props.dbSet('naming.horse.responseTime', this.sinceMount());
        this.props.dbSet('naming.horse.answer', this.state.answer);
        this.props.dbSet(
            'naming.horse.isCorrect',
            Object.values(this.i18n.raw[this.props.language].translation.questionnaire.naming.horse.answers).includes(this.normalizeWord(this.state.answer))
        );
        this.props.nextStep();
    }

    render() {
        return (
            <>
                <QuestionnairePartialCard
                    onSubmit={(e) => this.onSubmit(e)}
                    side={
                        <Card.Img variant="top" src={coverPhoto} />
                    }
                    imgMaxWidth={`50vh`}
                    title={this.t(`questionnaire.naming.horse.title`)}
                    body={
                        <>
                            <Card.Text>
                                {this.t(`questionnaire.naming.horse.question`)}
                            </Card.Text>

                            <Form.Group>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder={this.t(`questionnaire.common.typeName`)}
                                    value={this.state.answer}
                                    onChange={(e) => this.onChange('answer', e)}
                                />
                            </Form.Group>

                            <Button type="submit" variant="primary">
                                {this.t(`questionnaire.common.saveAndNextStep`)}
                            </Button>
                        </>
                    }
                />
            </>
        )
    }

}

export default StepNamingHorse;