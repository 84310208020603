import React from 'react'
import BaseComponent from '../../../../common/base';
import QuestionnairePartialCard from '../../../partials/card';

export class StepMemoryNumbers extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = Object.assign(
            this.state,
            {
                index: 0,
                loaded: false,
                number: this.t(`questionnaire.common.loading`),
                loadedCount: 0,
                preparation: true
            }
        )

        this.numbers = [];
        if (this.props.section === "numbersAsc") {
            this.numbers = [
                { "number": null, preparation: true },
                { "number": null, preparation: true },
                { "number": null, preparation: true },
                { "number": "2", preparation: false },
                { "number": "1", preparation: false },
                { "number": "8", preparation: false },
                { "number": "5", preparation: false },
                { "number": "4", preparation: false }
            ];
        } else if (this.props.section === "numbersDesc") {
            this.numbers = [
                { "number": null, preparation: true },
                { "number": null, preparation: true },
                { "number": null, preparation: true },
                { "number": "7", preparation: false },
                { "number": "4", preparation: false },
                { "number": "2", preparation: false }
            ];
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.readNext();
    }

    readNext() {
        setTimeout((self) => {
            self.setState(
                {
                    number: self.numbers[self.state.index].number,
                    index: self.state.index + 1,
                    preparation: self.numbers[self.state.index].preparation
                }
            );
            if (self.state.index < self.numbers.length) {
                self.readNext();
            }
            setTimeout((self2) => {
                self.setState({ number: "" });
                if (self2.state.index === self2.numbers.length) {
                    self2.onSubmit();
                }
            }, 500, self);
        }, 1000, this);
    }

    onSubmit(e) {
        this.props.nextStep();
    }

    render() {
        return (
            <>
                <QuestionnairePartialCard
                    title={this.t(`questionnaire.memory.${this.props.section}.title`)}
                    body={
                        <>
                            <p className="display-4">{this.state.number || <>&nbsp;</>}</p>
                        </>
                    }
                />
            </>
        )
    }

}

export default StepMemoryNumbers;