import React from 'react'
import BaseComponent from '../../../common/base';
import { Card, Button } from 'react-bootstrap';
import QuestionnairePartialCard from '../../partials/card';
import coverPhoto from '../../../../assets/images/safe3x.png'

export class StepMemoryIntroduction extends BaseComponent {

    render() {
        return (
            <>
                <QuestionnairePartialCard
                    img={coverPhoto}
                    title={this.t(`questionnaire.memory.introduction.${this.props.section}.title`)}
                    body={
                        <>
                            <Card.Text>
                                {this.t(`questionnaire.memory.introduction.${this.props.section}.text.01.${this.props.gender}`)}
                            </Card.Text>

                            <Card.Text>
                                {this.t(`questionnaire.memory.introduction.${this.props.section}.text.02.${this.props.gender}`)}
                            </Card.Text>

                            <Card.Text>
                                <strong>{this.t(`questionnaire.memory.introduction.${this.props.section}.text.03.${this.props.gender}`)}</strong>
                            </Card.Text>

                            <Button variant="primary" onClick={(e) => this.props.nextStep(e)}>
                                {this.t(`questionnaire.common.startTask`)}
                            </Button>
                        </>
                    }
                />
            </>
        )
    }

}

export default StepMemoryIntroduction;