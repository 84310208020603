import React from 'react'
import BaseComponent from '../../../common/base';
import { Card, Button } from 'react-bootstrap';
import QuestionnairePartialCard from '../../partials/card';
import coverPhoto from '../../../../assets/images/medal3x.png'

export class StepPreparationDone extends BaseComponent {

    render() {
        return (
            <>
                <QuestionnairePartialCard
                    img={coverPhoto}
                    title={this.t('questionnaire.preparation.done.title')}
                    body={
                        <>
                            <Card.Text>
                                {this.t(`questionnaire.preparation.done.text`)}
                            </Card.Text>
                            <Button variant="primary" onClick={(e) => this.props.nextStep(e)}>
                                {this.t('questionnaire.preparation.done.start')}
                            </Button>
                        </>
                    }
                />
            </>
        )
    }

}

export default StepPreparationDone;