import React from 'react'
import BaseComponent from '../../../common/base';
import { Card } from 'react-bootstrap';
import QuestionnairePartialCard from '../../partials/card';
import fsc from '../../../common/fullscreen';

export class StepCsatThankYou extends BaseComponent {

    componentDidMount() {
        super.componentDidMount();
        fsc.closeFullscreen();
    }

    render() {
        return (
            <>
                <QuestionnairePartialCard
                    title={this.t(`questionnaire.csat.thankYou.title`)}
                    body={
                        <>
                            <Card.Text>
                                {this.t(`questionnaire.csat.thankYou.text.01.${this.props.gender}`)}
                            </Card.Text>

                            <Card.Text>
                                {this.t(`questionnaire.csat.thankYou.text.02.01`)}
                                <br />
                                {this.t(`questionnaire.csat.thankYou.text.02.02`)}
                            </Card.Text>
                        </>
                    }
                    footer={
                        <span>
                            {this.t(`questionnaire.csat.thankYou.footer`)}
                        </span>
                    }
                />
            </>
        )
    }

}

export default StepCsatThankYou;