import React from 'react'
import BaseComponent from '../../../common/base';
import { Button } from 'react-bootstrap';
import QuestionnairePartialCard from '../../partials/card';

import letterAPL from '../../../../data/translation/pl/letter-a.wav';
import letterBPL from '../../../../data/translation/pl/letter-b.wav';
import letterCPL from '../../../../data/translation/pl/letter-c.wav';
import letterDPL from '../../../../data/translation/pl/letter-d.wav';
import letterEPL from '../../../../data/translation/pl/letter-e.wav';
import letterFPL from '../../../../data/translation/pl/letter-f.wav';
import letterJPL from '../../../../data/translation/pl/letter-j.wav';
import letterKPL from '../../../../data/translation/pl/letter-k.wav';
import letterLPL from '../../../../data/translation/pl/letter-l.wav';
import letterMPL from '../../../../data/translation/pl/letter-m.wav';
import letterNPL from '../../../../data/translation/pl/letter-n.wav';
import letterOPL from '../../../../data/translation/pl/letter-o.wav';

import letterAEN from '../../../../data/translation/en/letter-a.wav';
import letterBEN from '../../../../data/translation/en/letter-b.wav';
import letterCEN from '../../../../data/translation/en/letter-c.wav';
import letterDEN from '../../../../data/translation/en/letter-d.wav';
import letterEEN from '../../../../data/translation/en/letter-e.wav';
import letterFEN from '../../../../data/translation/en/letter-f.wav';
import letterJEN from '../../../../data/translation/en/letter-j.wav';
import letterKEN from '../../../../data/translation/en/letter-k.wav';
import letterLEN from '../../../../data/translation/en/letter-l.wav';
import letterMEN from '../../../../data/translation/en/letter-m.wav';
import letterNEN from '../../../../data/translation/en/letter-n.wav';
import letterOEN from '../../../../data/translation/en/letter-o.wav';

export class StepAttentionLetters extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = Object.assign(
            this.state,
            {
                index: 0,
                loaded: false,
                letter: 'loading',
                loadedCount: 0,
                preparation: true
            }
        )

        this.letters = [
            { "letter": "3", "audio": { "pl": { "url": null, "obj": null }, "en": { "url": null, "obj": null } } },
            { "letter": "2", "audio": { "pl": { "url": null, "obj": null }, "en": { "url": null, "obj": null } } },
            { "letter": "1", "audio": { "pl": { "url": null, "obj": null }, "en": { "url": null, "obj": null } } },
            { "letter": "F", "audio": { "pl": { "url": letterFPL, "obj": null }, "en": { "url": letterFEN, "obj": null } } },
            { "letter": "B", "audio": { "pl": { "url": letterBPL, "obj": null }, "en": { "url": letterBEN, "obj": null } } },
            { "letter": "A", "audio": { "pl": { "url": letterAPL, "obj": null }, "en": { "url": letterAEN, "obj": null } } },
            { "letter": "C", "audio": { "pl": { "url": letterCPL, "obj": null }, "en": { "url": letterCEN, "obj": null } } },
            { "letter": "M", "audio": { "pl": { "url": letterMPL, "obj": null }, "en": { "url": letterMEN, "obj": null } } },
            { "letter": "N", "audio": { "pl": { "url": letterNPL, "obj": null }, "en": { "url": letterNEN, "obj": null } } },
            { "letter": "A", "audio": { "pl": { "url": letterAPL, "obj": null }, "en": { "url": letterAEN, "obj": null } } },
            { "letter": "A", "audio": { "pl": { "url": letterAPL, "obj": null }, "en": { "url": letterAEN, "obj": null } } },
            { "letter": "J", "audio": { "pl": { "url": letterJPL, "obj": null }, "en": { "url": letterJEN, "obj": null } } },
            { "letter": "K", "audio": { "pl": { "url": letterKPL, "obj": null }, "en": { "url": letterKEN, "obj": null } } },
            { "letter": "L", "audio": { "pl": { "url": letterLPL, "obj": null }, "en": { "url": letterLEN, "obj": null } } },
            { "letter": "B", "audio": { "pl": { "url": letterBPL, "obj": null }, "en": { "url": letterBEN, "obj": null } } },
            { "letter": "A", "audio": { "pl": { "url": letterAPL, "obj": null }, "en": { "url": letterAEN, "obj": null } } },
            { "letter": "F", "audio": { "pl": { "url": letterFPL, "obj": null }, "en": { "url": letterFEN, "obj": null } } },
            { "letter": "A", "audio": { "pl": { "url": letterAPL, "obj": null }, "en": { "url": letterAEN, "obj": null } } },
            { "letter": "K", "audio": { "pl": { "url": letterKPL, "obj": null }, "en": { "url": letterKEN, "obj": null } } },
            { "letter": "D", "audio": { "pl": { "url": letterDPL, "obj": null }, "en": { "url": letterDEN, "obj": null } } },
            { "letter": "E", "audio": { "pl": { "url": letterEPL, "obj": null }, "en": { "url": letterEEN, "obj": null } } },
            { "letter": "A", "audio": { "pl": { "url": letterAPL, "obj": null }, "en": { "url": letterAEN, "obj": null } } },
            { "letter": "A", "audio": { "pl": { "url": letterAPL, "obj": null }, "en": { "url": letterAEN, "obj": null } } },
            { "letter": "A", "audio": { "pl": { "url": letterAPL, "obj": null }, "en": { "url": letterAEN, "obj": null } } },
            { "letter": "J", "audio": { "pl": { "url": letterJPL, "obj": null }, "en": { "url": letterJEN, "obj": null } } },
            { "letter": "A", "audio": { "pl": { "url": letterAPL, "obj": null }, "en": { "url": letterAEN, "obj": null } } },
            { "letter": "M", "audio": { "pl": { "url": letterMPL, "obj": null }, "en": { "url": letterMEN, "obj": null } } },
            { "letter": "O", "audio": { "pl": { "url": letterOPL, "obj": null }, "en": { "url": letterOEN, "obj": null } } },
            { "letter": "F", "audio": { "pl": { "url": letterFPL, "obj": null }, "en": { "url": letterFEN, "obj": null } } },
            { "letter": "A", "audio": { "pl": { "url": letterAPL, "obj": null }, "en": { "url": letterAEN, "obj": null } } },
            { "letter": "A", "audio": { "pl": { "url": letterAPL, "obj": null }, "en": { "url": letterAEN, "obj": null } } },
            { "letter": "B", "audio": { "pl": { "url": letterBPL, "obj": null }, "en": { "url": letterBEN, "obj": null } } },
        ];
        this.theLetter = 'A';
        this.lastLetterATimestamp = 0;
        this.errorCount = 11;
        this.strikeHistory = [];
    }

    componentDidMount() {
        super.componentDidMount();
        this.loadAll();
    }

    loadAll() {
        this.letters.map((elem) => {
            if (!elem.audio[this.props.language]?.url) {
                setTimeout((self) => { self.loadedAudioEvent(); }, 100, this);
                return elem;
            }
            let audio = new Audio();
            audio.addEventListener('canplaythrough', () => { this.loadedAudioEvent() }, false);
            audio.preload = 'auto';
            audio.src = elem.audio[this.props.language].url;
            audio.load();
            elem.audio[this.props.language].obj = audio;
            return elem;
        });
    }

    loadedAudioEvent() {
        this.setState(
            { loadedCount: this.state.loadedCount + 1 },
            () => {
                if (this.state.loadedCount === this.letters.length) {
                    this.readNext();
                }
            }
        );
    }

    readNext() {
        setTimeout((self) => {
            if (self.letters[self.state.index].audio[this.props.language]?.obj) {
                // disabled for all languages {
                // self.letters[self.state.index].audio[this.props.language].obj.play();
                // }
            }
            if (self.letters[self.state.index].letter === this.theLetter) {
                self.lastLetterATimestamp = self.now();
            }
            self.setState(
                {
                    letter: self.letters[self.state.index].letter,
                    index: self.state.index + 1,
                    preparation: ["3", "2", "1"].includes(self.letters[self.state.index].letter)
                }
            );
            if (self.state.index < self.letters.length) {
                self.readNext();
            }
            setTimeout((self2) => {
                self.setState({ letter: "" });
                if (self2.state.index === self2.letters.length) {
                    self2.onSubmit();
                }
            }, 500, self);
        }, 1000, this);
    }

    strike(e) {
        e.preventDefault();
        const currentIndex = this.state.index - 1;
        const currentLetter = this.letters[currentIndex].letter;
        if (
            currentLetter === this.theLetter
            &&
            this.strikeHistory.includes(currentIndex) === false
        ) {
            this.strikeHistory.push(currentIndex);
            this.reaction.push(this.now() - this.lastLetterATimestamp);
            this.errorCount = this.errorCount - 1;
        }
    }

    onSubmit(e) {
        this.props.dbSet('attention.letters.errorCount', this.errorCount);
        this.props.dbSet('attention.letters.reactionTimeAvg', this.averageReactionTime());
        this.props.nextStep();
    }

    render() {
        return (
            <>
                <QuestionnairePartialCard
                    title={
                        this.state.preparation
                            ?
                            this.t(`questionnaire.attention.letters.title.preparation`)
                            :
                            this.t(`questionnaire.attention.letters.title.currentLetter`)
                    }
                    body={
                        <>
                            <p className="display-4">{this.state.letter ? this.t(`questionnaire.char.${this.state.letter}`) : <>&nbsp;</>}</p>
                        </>
                    }
                    footer={
                        <Button block variant="primary" className="btn-notice" onClick={(e) => this.strike(e)} style={{ textTransform: "uppercase" }} disabled={this.state.preparation}>
                            {this.t(`questionnaire.common.now`)}
                        </Button>
                    }
                />
            </>
        )
    }

}

export default StepAttentionLetters;