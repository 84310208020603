import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from '../../data/translation/en.json';
import pl from '../../data/translation/pl.json';
import uk from '../../data/translation/uk.json';
import ru from '../../data/translation/ru.json';

const resources = {
    en: {
        translation: en
    },
    pl: {
        translation: pl
    },
    uk: {
        translation: uk
    },
    ru: {
        translation: ru
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        keySeparator: ".",
        interpolation: {
            escapeValue: false
        }
    });

const bundle = { engine: i18n, raw: resources };

export default bundle;