import React from 'react'
import BaseComponent from '../../../common/base';
import { Card, Form, Button } from 'react-bootstrap';
import QuestionnairePartialCard from '../../partials/card';

export class StepCsatQuestionnaire extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = Object.assign(
            this.state,
            {
                information: "",
                questions: "",
                technical: "",
                discomfort: "",
                motivation: [],
                notes: ""
            }
        );

    }

    onSubmit(e) {
        const answers = {
            information: this.state.information,
            questions: this.state.questions,
            technical: this.state.technical,
            discomfort: this.state.discomfort,
            motivation: this.state.motivation,
            notes: this.state.notes
        };
        this.props.dbSet('csat.reactionTime', this.firstReactionTime());
        this.props.dbSet('csat.responseTime', this.sinceMount());
        this.props.dbSet('csat.answers', answers);
        this.props.nextStep();
    }
    
    render() {
        return (
            <>
                <QuestionnairePartialCard
                    onSubmit={(e) => this.onSubmit(e)}
                    title={this.t(`questionnaire.csat.title`)}
                    body={
                        <>
                            {
                                ['information', 'questions', 'technical', 'discomfort'].map(section => {
                                    return (
                                        <React.Fragment key={`section-${section}`}>
                                            <Card.Text>
                                                {this.t(`questionnaire.csat.question.${section}.${this.props.gender}`)}
                                            </Card.Text>
                                            {
                                                ['yes', 'no'].map(itm => {
                                                    return (
                                                        <Form.Group key={`radio-csat-${section}-${itm}`}>
                                                            <Form.Check
                                                                custom
                                                                required
                                                                type={'radio'}
                                                                name={`csat-${section}`}
                                                                value={itm}
                                                                id={`radio-csat-${section}-${itm}`}
                                                                label={this.t(`questionnaire.csat.answer.${itm}`)}
                                                                checked={this.state[section] === itm}
                                                                onChange={(e) => this.onChange(section, e)}
                                                            />
                                                        </Form.Group>
                                                    )
                                                })
                                            }
                                        </React.Fragment>
                                    )
                                })
                            }

                            <Card.Text>
                                {this.t(`questionnaire.csat.question.motivation.${this.props.gender}`)}
                            </Card.Text>

                            {
                                ['curiosity', 'worry', 'recommendation', 'report', 'other'].map(itm => {
                                    return (
                                        <Form.Group key={`checkbox-csat-motivation-${itm}`}>
                                            <Form.Check
                                                custom
                                                type={'checkbox'}
                                                name={`csat-motivation`}
                                                value={itm}
                                                id={`checkbox-csat-motivation-${itm}`}
                                                label={this.t(`questionnaire.csat.answer.${itm}`)}
                                                checked={this.state.motivation.includes(itm)}
                                                onChange={(e) => { this.onCheckBoxChange('motivation', itm) }}
                                            />
                                        </Form.Group>
                                    )
                                })
                            }

                            <Card.Text>
                                {this.t(`questionnaire.csat.question.additional.${this.props.gender}`)}
                            </Card.Text>

                            <Form.Group>
                                <Form.Control
                                    rows={3}
                                    value={this.state.notes}
                                    onChange={(e) => { this.onChange('notes', e) }}
                                    as="textarea"
                                    type="text"
                                    placeholder={`...`}
                                />
                            </Form.Group>

                        </>
                    }
                    footer={
                        <Button type="submit" variant="primary">
                            {this.t(`questionnaire.common.saveAndFinishCsat`)}
                        </Button>
                    }
                />
            </>
        )
    }

}

export default StepCsatQuestionnaire;