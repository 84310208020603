import React from 'react'
import BaseComponent from '../../../../common/base';
import { Form, Button } from 'react-bootstrap';
import QuestionnairePartialCard from '../../../partials/card';
import cosineDistance from '../../../../common/cosineDistance';

export class StepMemoryCheckSentences extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = Object.assign(
            this.state,
            {
                sentence: ""
            }
        )

    }

    onSubmit(e) {
        const { sentence } = this.state;
        const { section } = this.props;

        this.props.dbSet(`memory.${section}.reactionTime`, this.firstReactionTime());
        this.props.dbSet(`memory.${section}.responseTime`, this.sinceMount());
        this.props.dbSet(`memory.${section}.errorCount`, 1 - cosineDistance.textCosineSimilarity(sentence, this.t(`questionnaire.memory.sentences.${section}`)));
        this.props.dbSet(`memory.${section}.answer`, sentence);
        this.props.nextStep();
    }

    render() {

        return (
            <>
                <QuestionnairePartialCard
                    onSubmit={(e) => this.onSubmit(e)}
                    title={this.t(`questionnaire.memory.check.${this.props.section}.title`)}
                    body={
                        <>
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    placeholder={`...`}
                                    value={this.state.sentence}
                                    onChange={
                                        (e) => {
                                            this.measureReaction();
                                            this.setState({ sentence: e.target.value })
                                        }
                                    }
                                />
                            </Form.Group>
                        </>
                    }
                    footer={
                        <Button type="submit" variant="primary">
                            {this.t('questionnaire.common.saveAndNextStep')}
                        </Button>
                    }
                />
            </>
        )
    }

}

export default StepMemoryCheckSentences;