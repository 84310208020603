import React from 'react'
import BaseComponent from '../../../common/base';
import { Button } from 'react-bootstrap';
import QuestionnairePartialCard from '../../partials/card';
import random from 'random';

export class StepStroopWords extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = Object.assign(
            this.state,
            {
                localStep: 0
            }
        )
        this.colors = [
            { variant: 'success', ident: 'green', name: this.t(`questionnaire.common.green`) },
            { variant: 'danger', ident: 'red', name: this.t(`questionnaire.common.red`) },
            { variant: 'warning', ident: 'yellow', name: this.t(`questionnaire.common.yellow`) },
            { variant: 'info', ident: 'blue', name: this.t(`questionnaire.common.blue`) },
        ];

        this.procedureLength = 40;
        this.procedure = [];
        this.buildProcedure();

        this.lastTimestamp = this.now();
        this.errorCount = this.procedureLength;
        this.props.dbSet('stroop.errorCount', this.procedureLength);
    }

    buildProcedure() {

        let uniform = random.uniform(0, 1);
        let prevFirstIndex = Math.floor(uniform() * this.colors.length);
        let prevSecondIndex = Math.floor(uniform() * this.colors.length);

        for (let i = 0; i < this.procedureLength; i++) {
            let theSame = uniform() < .2;
            let firstIndex = prevFirstIndex;
            let secondIndex = prevSecondIndex;

            while (
                firstIndex === secondIndex
                || firstIndex === prevFirstIndex
                || secondIndex === prevSecondIndex
            ) {
                firstIndex = Math.floor(uniform() * this.colors.length);
                secondIndex = Math.floor(uniform() * this.colors.length);
            };

            prevFirstIndex = firstIndex;
            prevSecondIndex = secondIndex;

            if (theSame) {
                let newItem = {
                    text: this.colors[firstIndex],
                    color: this.colors[firstIndex]
                }
                this.procedure.push(newItem);
            } else {
                let newItem = {
                    text: this.colors[firstIndex],
                    color: this.colors[secondIndex]
                }
                this.procedure.push(newItem);
            }
        }
    }

    validateColor(e, selectedColor) {
        e.preventDefault();

        let nextLocalStep = this.state.localStep + 1;
        if (nextLocalStep < this.procedure.length) {

            this.reaction.push(this.now() - this.lastTimestamp);

            if (selectedColor === this.procedure[this.state.localStep].color.ident) {
                this.errorCount = this.errorCount - 1;
            }

            this.setState(
                {
                    localStep: nextLocalStep
                },
                () => {
                    this.lastTimestamp = this.now()
                }
            )
        } else {
            this.onSubmit();
        }
    }

    onSubmit(e) {
        this.props.dbSet('stroop.errorCount', this.errorCount);
        this.props.dbSet('stroop.reactionTimeAvg', this.averageReactionTime());
        this.props.nextStep();
    }

    render() {

        const text = this.procedure[this.state.localStep].text.name;
        const color = this.procedure[this.state.localStep].color.variant;

        return (
            <>
                <QuestionnairePartialCard
                    onSubmit={(e) => this.onSubmit(e)}
                    title={this.t(`questionnaire.stroop.words.title`)}
                    body={
                        <>
                            <p className={`h3 text-${color}`}>
                                <strong>
                                    {text}
                                </strong>
                            </p>
                        </>
                    }
                    footer={
                        <div style={{ width: "100%" }}>
                            {this.colors.map(item => {
                                return (
                                    <Button
                                        type="submit"
                                        key={'btn-' + item.variant}
                                        variant="secondary"
                                        className="ml-1 mr-1 mb-1 btn-notice-secondary"
                                        onClick={(e) => { this.validateColor(e, item.ident); }}
                                    >
                                        {item.name}
                                    </Button>
                                )
                            })}
                        </div>
                    }
                />
            </>
        )
    }

}

export default StepStroopWords;