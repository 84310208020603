import React from 'react'
import BaseComponent from '../../../../common/base';
import { Card, Button } from 'react-bootstrap';
import QuestionnairePartialCard from '../../../partials/card';

export class StepSleepEpworthIntroduction extends BaseComponent {

    render() {
        return (
            <>
                <QuestionnairePartialCard
                    title={this.t('questionnaire.sleep.epworth.introduction.title')}
                    body={
                        <>
                            <Card.Text>
                                {this.t(`questionnaire.sleep.epworth.introduction.text.01.${this.props.gender}`)}
                            </Card.Text>

                            <Card.Text>
                                {this.t(`questionnaire.sleep.epworth.introduction.text.02.${this.props.gender}`)}
                            </Card.Text>

                            <Card.Text>
                                <strong>{this.t(`questionnaire.sleep.epworth.introduction.text.03.${this.props.gender}`)}</strong>
                            </Card.Text>

                            <Button variant="primary" onClick={(e) => this.props.nextStep(e)}>
                                {this.t(`questionnaire.common.startTask`)}
                            </Button>
                        </>
                    }
                />
            </>
        )
    }

}

export default StepSleepEpworthIntroduction;