import React from 'react'
import BaseComponent from '../../common/base';
import { Button, Card, Form, Modal, Row, Col } from 'react-bootstrap';

export class SubscribeTablet extends BaseComponent {

    constructor(props) {
        super(props);

        this.baseState = {
            showModal: false,
            isLoading: false,
            redcapDomain: "umass",
            redcapSupervisorEmailAddress: "andrzej.przybyszewski@umassmed.edu",
            redcapRecordId: "",
            region: this.props.region || "eu",
            gender: "",
            age: "",
            mode: "manual",
            version: "01",
            consent: [],
            allConsents: ['toParticipate', 'toEmailProcessing', 'toVoiceProcessing', 'toImageProcessing', 'toResultsProcessing'],
            createdUrl: "",
            createdIdent: "",
            anonymous: false,
        };

        this.state = Object.assign(
            this.state,
            this.baseState
        );

    }

    onSubmit(e) {
        e.preventDefault();
        let self = this;
        let baseUrl = `${process.env.REACT_APP_URL}/${self.state.region}/${self.props.language}/${self.state.version}/`;
        self.setState({ isLoading: true });
        self.props.showToast({ type: 'info', txt: this.t('landing.tablet.popup.saving') });
        self.api.request(
            `/participant/subscribe?language=${self.props.language}&region=${self.state.region}`,
            {
                'participant': {
                    'redcapDomain': self.state.redcapDomain,
                    'redcapSupervisorEmailAddress': self.state.redcapSupervisorEmailAddress,
                    'redcapRecordId': self.state.redcapRecordId,
                    'gender': self.state.gender,
                    'age': self.state.age,
                    'language': 'en',
                    'region': 'us',
                    'consentToParticipate': self.state.consent.includes('toParticipate'),
                    'consentToEmailProcessing': self.state.consent.includes('toParticipate'),
                    'consentToVoiceProcessing': self.state.consent.includes('toVoiceProcessing'),
                    'consentToImageProcessing': self.state.consent.includes('toImageProcessing'),
                    'consentToResultsProcessing': self.state.consent.includes('toParticipate'),
                    'new': true,
                },
                'questionnaire': {
                    'version': self.state.version,
                    'endpoint': 'tablet'
                }
            },
            (data) => {
                const newUrl = baseUrl + data.questionnaire.uuid;
                self.props.showToast({
                    type: 'success',
                    txt: this.t('landing.tablet.popup.saved')
                });
                self.setState({
                    ...this.baseState,
                }, () => {
                    setTimeout(() => {
                        window.location.replace(newUrl);
                    }, 3000);
                });
            },
            (details) => {
                self.props.showToast({
                    type: 'error',
                    txt: details.data.error
                });
                self.setState({
                    isLoading: false
                });
            }
        );
    }

    setAnonymous(e) {
        const newAnonym = !this.state.anonymous;
        const newRedcapRecordId = newAnonym ? 'S_ANON' : '';
        this.setState({ anonymous: newAnonym, redcapRecordId: newRedcapRecordId });
    }

    render() {

        const modal = (
            <Modal
                size="lg"
                centered
                show={this.state.showModal}
                onHide={() => this.setState({ showModal: false })}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
                    <p>{this.t('landing.tablet.modal.text.02')}</p>
                    <Form.Control
                        required
                        readOnly={true}
                        as="textarea"
                        rows={3}
                        value={this.state.createdUrl}
                        onChange={(e) => { }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="success"
                        href={this.state.createdUrl}
                        target="_blank"
                    >
                        {this.t('landing.tablet.modal.open')}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={
                            (e) =>
                                this.setState(
                                    {
                                        showModal: false
                                    }
                                )
                        }
                    >
                        {this.t('landing.tablet.modal.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )

        const formBody = (
            <>
                <Row>
                    <Col sm={12} lg={4}>
                        <Form.Group>
                            <Form.Label>
                                {this.t('landing.tablet.redcapRecordId.label')}
                            </Form.Label>
                            <Form.Control
                                required
                                type="text"
                                disabled={this.state.anonymous}
                                placeholder={this.t('landing.tablet.redcapRecordId.placeholder')}
                                value={this.state.redcapRecordId}
                                onChange={(e) => this.onChange('redcapRecordId', e)}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={12} lg={4}>
                        <Form.Group>
                            <Form.Label>
                                {this.t('landing.tablet.age.label')}
                            </Form.Label>
                            <Form.Control
                                required
                                type="number"
                                min="18"
                                max="128"
                                placeholder={this.t('landing.tablet.age.placeholder')}
                                value={this.state.age}
                                onChange={(e) => this.onChange('age', e)}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={12} lg={4}>
                        <Form.Group className="">
                            <Form.Label className="mr-3 w-100">
                                {this.t('landing.tablet.gender.label')}
                            </Form.Label>
                            <div className='border rounded-lg' style={{ padding: '0.375rem 0.75rem' }}>
                                <Form.Check
                                    custom
                                    inline
                                    required
                                    type="radio"
                                    name="gender"
                                    id="gender-female"
                                    className="mr-3 my-0 py-0"
                                    value="f"
                                    label={this.t('landing.tablet.gender.female')}
                                    checked={this.state.gender === 'f'}
                                    onChange={(e) => this.onChange('gender', e)}
                                />
                                <Form.Check
                                    custom
                                    inline
                                    required
                                    type="radio"
                                    name="gender"
                                    id="gender-male"
                                    className='my-0 py-0'
                                    value="m"
                                    label={this.t('landing.tablet.gender.male')}
                                    checked={this.state.gender === 'm'}
                                    onChange={(e) => this.onChange('gender', e)}
                                />
                            </div>
                        </Form.Group>
                    </Col>

                    <Col sm={12}>
                        <Form.Group controlId="consentToParticipate" className=' rounded-lg border shadow p-3 mt-2'>
                            <Form.Check
                                custom
                                required
                                type="checkbox"
                                label={this.t('landing.tablet.consent.toParticipate')}
                                checked={this.state.consent.includes('toParticipate')}
                                onChange={(e) => this.onCheckBoxChange('consent', 'toParticipate')}
                            />
                        </Form.Group>
                    </Col>
                </Row>

            </>
        )

        return (
            <>
                {modal}
                <Form method="post" onSubmit={(e) => this.onSubmit(e)} >
                    <Card className="card-enrol">
                        <Card.Header>
                            {this.t('landing.tablet.header')}
                        </Card.Header>
                        <Card.Body>
                            {formBody}
                        </Card.Body>
                        <Card.Footer className="text-muted text-center" style={{ fontSize: "0.8rem" }}>
                            <div className="text-center">
                                <Button variant="purple" size="lg" type="submit" className="pl-5 pr-5 pt-2 pb-2" disabled={this.state.isLoading}>
                                    {this.t('landing.tablet.submit')}
                                </Button>
                            </div>
                        </Card.Footer>
                    </Card>
                </Form>
            </>
        )
    }
}

export default SubscribeTablet