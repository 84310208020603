import React from 'react'
import { Card } from 'react-bootstrap';
import BaseComponent from '../../../common/base';
import preloadImages from "../../../common/preloadImages.js";
import QuestionnairePartialCard from '../../partials/card';
import coverPhoto from '../../../../assets/images/sandglass3x.png'

import IMG_DC_1317_anger from '../../../../assets/images/DC_1317_anger.jpg';
import IMG_JG_0134_disgust from '../../../../assets/images/JG_0134_disgust.jpg';
import IMG_KM_1797_surprise from '../../../../assets/images/KM_1797_surprise.jpg';
import IMG_KP_0051_happiness from '../../../../assets/images/KP_0051_happiness.jpg';
import IMG_KS_2052_sadness from '../../../../assets/images/KS_2052_sadness.jpg';
import IMG_MB_0048_happiness from '../../../../assets/images/MB_0048_happiness.jpg';
import IMG_MR2_1829_fear from '../../../../assets/images/MR2_1829_fear.jpg';
import IMG_alarm_clock3x from '../../../../assets/images/alarm_clock3x.png';
import IMG_brain3x from '../../../../assets/images/brain3x.png';
import IMG_camera3x from '../../../../assets/images/camera3x.png';
import IMG_champion3x from '../../../../assets/images/champion3x.png';
import IMG_cinema3x from '../../../../assets/images/cinema3x.png';
import IMG_coffee3x from '../../../../assets/images/coffee3x.png';
import IMG_color3x from '../../../../assets/images/color3x.png';
import IMG_compass3x from '../../../../assets/images/compass3x.png';
import IMG_cow from '../../../../assets/images/cow.jpg';
import IMG_cube from '../../../../assets/images/cube.svg';
import IMG_darts3x from '../../../../assets/images/darts3x.png';
import IMG_graph3x from '../../../../assets/images/graph3x.png';
import IMG_head3x from '../../../../assets/images/head3x.png';
import IMG_heart3x from '../../../../assets/images/heart3x.png';
import IMG_hero0 from '../../../../assets/images/hero0.jpg';
import IMG_hero1 from '../../../../assets/images/hero1.jpg';
import IMG_hero2 from '../../../../assets/images/hero2.jpg';
import IMG_horse from '../../../../assets/images/horse.jpg';
import IMG_icon_256x256 from '../../../../assets/images/icon_256x256.png';
import IMG_ideas3x from '../../../../assets/images/ideas3x.png';
import IMG_lion from '../../../../assets/images/lion.jpg';
import IMG_location from '../../../../assets/images/location.png';
import IMG_magnifier3x from '../../../../assets/images/magnifier3x.png';
import IMG_medal3x from '../../../../assets/images/medal3x.png';
import IMG_microphone3x from '../../../../assets/images/microphone3x.png';
import IMG_music3x from '../../../../assets/images/music3x.png';
import IMG_pencil3x from '../../../../assets/images/pencil3x.png';
import IMG_products3x from '../../../../assets/images/products3x.png';
import IMG_safe3x from '../../../../assets/images/safe3x.png';
import IMG_sale3x from '../../../../assets/images/sale3x.png';
import IMG_sandglass3x from '../../../../assets/images/sandglass3x.png';
import IMG_settings3x from '../../../../assets/images/settings3x.png';
import IMG_trailA from '../../../../assets/images/trailA.gif';
import IMG_trailB from '../../../../assets/images/trailB.gif';

export class StepPreparationLoading extends BaseComponent {

    constructor(props) {
        super(props);
        this.images = [
            IMG_DC_1317_anger,
            IMG_JG_0134_disgust,
            IMG_KM_1797_surprise,
            IMG_KP_0051_happiness,
            IMG_KS_2052_sadness,
            IMG_MB_0048_happiness,
            IMG_MR2_1829_fear,
            IMG_alarm_clock3x,
            IMG_brain3x,
            IMG_camera3x,
            IMG_champion3x,
            IMG_cinema3x,
            IMG_color3x,
            IMG_coffee3x,
            IMG_compass3x,
            IMG_cow,
            IMG_cube,
            IMG_darts3x,
            IMG_graph3x,
            IMG_head3x,
            IMG_heart3x,
            IMG_hero0,
            IMG_hero1,
            IMG_hero2,
            IMG_horse,
            IMG_icon_256x256,
            IMG_ideas3x,
            IMG_lion,
            IMG_location,
            IMG_magnifier3x,
            IMG_medal3x,
            IMG_microphone3x,
            IMG_music3x,
            IMG_pencil3x,
            IMG_products3x,
            IMG_safe3x,
            IMG_sale3x,
            IMG_sandglass3x,
            IMG_settings3x,
            IMG_trailA,
            IMG_trailB
        ];
        this.preloadedCount = 0;

        this.state = Object.assign(
            this.state,
            {
                status: 'assets',
                error: ''
            }
        );

    }

    componentDidMount() {
        super.componentDidMount();
        this.props.api.event('API_PROGRESS', 0);
        preloadImages(this.images)
            .forEach(
                p =>
                    p.then(
                        () => {
                            this.preloadedCount++;
                            this.handlePreloadProgress();
                        }
                    )
            );
    }

    handlePreloadProgress() {
        let percent = this.preloadedCount * 100 / this.images.length;
        this.props.api.event('API_PROGRESS', percent);
        if (percent === 100) {
            setTimeout((self) => {
                self.loadRemoteData();
            }, 1000, this);
        }
    }

    loadRemoteData() {
        let self = this;
        self.setState({ status: 'data' });
        self.api.request(
            `/participant/questionnaire/get?language=${self.props.language}&region=${self.props.region}`,
            {
                'questionnaire': {
                    'uuid': self.props.match.params.uuid,
                }
            },
            (data) => {
                const updatedDatabase = self.props.dbAssign(data);
                const nextStep = updatedDatabase.step === 0 ? 1 : updatedDatabase.step;
                self.props.setGender(updatedDatabase.gender);
                self.props.setStep(nextStep);
                self.props.setIsTablet(data.endpoint === 'tablet')
            },
            (details) => {
                self.setState({ status: 'error', error: details.data.error });
            }
        );
    }

    render() {

        const status = this.state.status;
        const title = ['assets', 'data'].includes(status)
            ? this.t(`questionnaire.preparation.loading.title`)
            : this.t(`questionnaire.preparation.error.title`);
        const content = ['assets', 'data'].includes(status)
            ? this.t(`questionnaire.preparation.loading.status.${status}`)
            : this.state.error;

        return (
            <>
                <QuestionnairePartialCard
                    img={coverPhoto}
                    title={title}
                    body={
                        <>
                            <Card.Text>
                                {content}
                            </Card.Text>
                        </>
                    }
                />
            </>
        )
    }

}

export default StepPreparationLoading;