import React from 'react'
import BaseComponent from '../common/base';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Helmet } from "react-helmet";

export class NotFoundPage extends BaseComponent {

    render() {
        return (
            <>
                <Helmet>
                    <title>404 - Page not found</title>
                </Helmet>

                <Container className="landing-page">

                    <Row
                    >
                        <Col md={12} lg={6} className="p-4">
                            <h1 className="pt-2 pb-4">
                                404
                            </h1>
                            <p>
                                Page not found.
                            </p>
                            <p className="pt-2">
                                <Button
                                    variant="purple"
                                    href={`${process.env.PUBLIC_URL}/`}
                                >
                                    Homepage
                                </Button>
                            </p>
                        </Col>
                    </Row>

                </Container>
            </>
        )
    }
}

export default NotFoundPage
