import React from 'react'
import BaseComponent from '../../../common/base';
import { Card, Form } from 'react-bootstrap';
import QuestionnairePartialCard from '../../partials/card';

export class StepFluencyTyping extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = Object.assign(
            this.state,
            {
                words: "",
                finished: false,
                countdown: 60,
                countdownTempo: 1000,
                averageReadingSpeed: 200,
                averageNumberOfWords: 20,
                lastChange: new Date()
            }
        );
    }

    componentDidMount() {
        super.componentDidMount();
        let startDelay = (this.state.averageNumberOfWords * 60 / this.state.averageReadingSpeed) * 1000;
        setTimeout((self) => {
            self.countdown();
        }, startDelay, this);
    }

    countdown() {
        setTimeout((self) => {

            let now = new Date();
            let difference = (now - self.state.lastChange) / 1000;

            if (difference < 3) {
                self.countdown();
                return;
            }

            let nextValue = self.state.countdown - 1;
            if (nextValue >= 0) {
                self.setState({ countdown: nextValue });
                self.countdown();
            } else {
                self.finalize();
            }
        }, this.state.countdownTempo, this)
    }

    finalize() {
        let self = this;

        this.props.dbSet('fluency.typing.reactionTime', this.firstReactionTime());
        this.props.dbSet('fluency.typing.responseTime', this.sinceMount());
        this.props.dbSet('fluency.typing.answers', this.state.words.split(/\s+/));

        self.setState({ finished: true });
        self.props.nextStep();
    }

    onDelayedChange(val) {
        this.measureReaction();
        this.setState({ words: val, lastChange: new Date() });
    }

    render() {
        return (
            <>
                <QuestionnairePartialCard
                    title={this.t('questionnaire.fluency.typing.title')}
                    body={
                        <>
                            <Card.Text>
                                {this.t('questionnaire.fluency.typing.text.01')}
                                &nbsp;
                                <strong>
                                    {this.t('questionnaire.fluency.typing.text.02')}
                                </strong>
                                {' '}
                                {this.t('questionnaire.fluency.typing.text.03')}
                                &nbsp;
                                <strong>
                                    {this.t('questionnaire.fluency.typing.text.04')}
                                </strong>.
                            </Card.Text>

                            <Card.Text>
                                {this.t('questionnaire.fluency.typing.text.05')}
                                {' '}
                                <strong>
                                    {this.t('questionnaire.fluency.typing.text.06')}
                                </strong>
                            </Card.Text>

                            <Form.Group>
                                <Form.Control
                                    required
                                    rows={5}
                                    value={this.state.words}
                                    readOnly={this.state.finished}
                                    onChange={(e) => { this.onDelayedChange(e.target.value) }}
                                    as="textarea"
                                    type="text"
                                    placeholder={this.t(`questionnaire.common.typeWords`)}
                                />
                            </Form.Group>
                        </>
                    }
                    footer={
                        <Card.Text>
                            {this.t('questionnaire.fluency.typing.tillEnd')}
                            {' '}
                            {this.state.countdown}
                            {' '}
                            {this.t('questionnaire.fluency.typing.seconds')}
                        </Card.Text>
                    }
                />
            </>
        )
    }

}

export default StepFluencyTyping;