import React from 'react'
import { Button } from 'react-bootstrap';
import BaseComponent from '../../../../common/base';
import QuestionnairePartialCard from '../../../partials/card';
import random from 'random';

export class PartialTrailEngine extends BaseComponent {

    constructor(props) {
        super(props);

        this.buttons = props.buttons;

        let uniform = random.uniform(0, 1);
        let prev = null;
        let updatedButtons = this.buttons.map((btn) => {
            const mt = uniform() * 2.25;
            const mr = uniform() * 2.25;
            const mb = uniform() * 2.25;
            const ml = uniform() * 2.25;
            let newBtn = {
                id: btn.id,
                prev: prev,
                clicked: false,
                className: "outline-secondary",
                mt: mt,
                mr: mr,
                mb: mb,
                ml: ml
            };
            prev = btn.id;
            return newBtn;
        });

        this.buttons = this.shuffleArray(updatedButtons);

        this.state = Object.assign(
            this.state,
            {
                buttons: this.buttons,
                lastId: null,
                errorCount: 0
            }
        )
    }

    onBtnClick(e, clickedButton) {
        let self = this;
        let newLastId = self.state.lastId;

        this.measureReaction();

        let incrementError = 0;
        if (clickedButton.className === "success") {
            this.setState({ errorCount: this.state.errorCount + incrementError });
            return;
        }

        const newButtons = this.state.buttons.map((element) => {
            let updatedElement = { ...element };
            if (updatedElement.className === "danger") {
                updatedElement.className = "outline-secondary";
            }
            if (updatedElement.id === clickedButton.id) {
                if (newLastId === clickedButton.prev) {
                    newLastId = clickedButton.id;
                    incrementError = 0;
                    updatedElement.className = "success";
                } else {
                    incrementError = 1;
                    updatedElement.className = "danger";
                }
            }
            return updatedElement;
        });

        this.setState(
            {
                buttons: newButtons,
                lastId: newLastId,
                errorCount: this.state.errorCount + incrementError
            },
            () => {
                const stillLeft = newButtons.filter((btn) => { return btn.className !== "success" });
                if (!stillLeft.length) {
                    this.onSubmit(false);
                }

            }
        );
    }

    onSubmit(skipped) {
        this.props.dbSet(`trail.${this.props.ident}.reactionTime`, this.firstReactionTime());
        this.props.dbSet(`trail.${this.props.ident}.responseTime`, this.sinceMount());
        this.props.dbSet(`trail.${this.props.ident}.errorCount`, this.state.errorCount);
        this.props.dbSet(`trail.${this.props.ident}.skipped`, skipped);
        this.props.nextStep();
    }

    render() {
        return (
            <>
                <QuestionnairePartialCard
                    title={this.props.title}
                    body={
                        <>
                            {
                                this.state.buttons.map((btn) => {
                                    return (
                                        <Button
                                            key={btn.id}
                                            variant={btn.className}
                                            onClick={(e) => { this.onBtnClick(e, btn) }}
                                            style={{
                                                width: "3rem",
                                                height: "3rem",
                                                lineHeight: "3rem",
                                                padding: 0,
                                                borderRadius: "50%",
                                                margin: `${btn.mt}rem ${btn.mr}rem ${btn.mb}rem ${btn.ml}rem`
                                            }}
                                        >
                                            {btn.id}
                                        </Button>
                                    )
                                })
                            }
                        </>
                    }
                    footer={
                        <>
                            <Button
                                variant="light"
                                className="float-right"
                                onClick={(e) => {
                                    this.onSubmit(true)
                                }}
                            >
                                {this.t(`questionnaire.common.skipThisStepTooHard`)}
                            </Button>
                        </>
                    }
                />
            </>
        )
    }

}

export default PartialTrailEngine;