
class API {

    constructor() {
        this.config = {
            data: [],
            storage: 'localStorage',
            xhrAbort: null,
            timeOffset: 0,
            url: process.env.REACT_APP_API_URL,
            env: process.env.REACT_APP_API_ENV,
            debug: process.env.REACT_APP_API_DBG,
            authorization: 'Bearer',
            language: null,
            region: null,
            token: null
        }
        this.audioCtx = null;
    }

    resolveParameter(pathIndex, defaults) {
        let languageFromPath = window.location.pathname.replace(process.env.PUBLIC_URL, '').split('/')[pathIndex];
        let resolvedLanguage = null;

        if (languageFromPath && languageFromPath.length === 2) {
            resolvedLanguage = languageFromPath.toLowerCase();
        } else {
            resolvedLanguage = window.navigator.userLanguage || window.navigator.language;
            resolvedLanguage = resolvedLanguage ? resolvedLanguage.split('-')[0].toLowerCase() : null;
        }

        return defaults.available.indexOf(resolvedLanguage) > -1 ? resolvedLanguage : defaults.available[0];
    }

    resolveRegion(defaults) {
        this.config.language = this.resolveParameter(1, defaults);
        return this.config.language;
    }

    resolveLanguage(defaults) {
        this.config.language = this.resolveParameter(2, defaults);
        return this.config.language;
    }

    initAudio() {
        this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    }

    confVal(key) {
        return this.config[key];
    }

    conf(key, value, store) {
        this.config[key] = value;
        if (store) {
            this.store(key, value);
        }
    }

    isDef(v) {
        return typeof v !== 'undefined';
    }

    event(eventName, value) {
        window.dispatchEvent(new CustomEvent(eventName, { 'detail': value }), true);
    }

    syncTime(ts) {
        var self = this;
        var resolve = function (data) {
            var dateStr = data.time.date;
            var serverTimeMillisGMT = Date.parse(new Date(Date.parse(dateStr)).toUTCString());
            var localMillisUTC = Date.parse(new Date().toUTCString());
            self.config.timeOffset = serverTimeMillisGMT - localMillisUTC;
        }
        resolve({ time: { date: ts } });
    }

    utcToLocal(date) {
        var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();
        newDate.setHours(hours - offset);
        return newDate;
    }

    getTime(asTimestamp) {
        var date = new Date();
        date.setTime(date.getTime() + this.config.timeOffset);
        var local = this.utcToLocal(date);
        if (asTimestamp) {
            return parseInt((local.getTime() / 1000).toFixed(0));
        }
        return local;
    }

    getContrast(hexcolor) {
        if (hexcolor.slice(0, 1) === '#') {
            hexcolor = hexcolor.slice(1);
        }

        if (hexcolor.length === 3) {
            hexcolor = hexcolor.split('').map(function (hex) {
                return hex + hex;
            }).join('');
        }

        var r = parseInt(hexcolor.substr(0, 2), 16);
        var g = parseInt(hexcolor.substr(2, 2), 16);
        var b = parseInt(hexcolor.substr(4, 2), 16);

        var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

        return (yiq >= 128) ? 'black' : 'white';
    }

    jsonForm(form, overwrite) {
        var obj = {};
        var elements = form.querySelectorAll("input, select, textarea");
        for (var i = 0; i < elements.length; ++i) {
            var element = elements[i];
            var name = element.name;
            var value = element.value;
            if (name) {
                if (overwrite === true) {
                    obj[name] = value;
                } else {
                    if (obj[name] !== undefined) {
                        if (!obj[name].push) {
                            obj[name] = [obj[name]];
                        }
                        obj[name].push(value || '');
                    } else {
                        obj[name] = value || '';
                    }
                }
            }
        }
        return obj;
    }

    cookieStore(sName, sValue, options) {
        var sCookie = encodeURIComponent(sName) + '=' + encodeURIComponent(sValue);
        if (options && options instanceof Date) {
            options = {
                expires: options
            };
        }
        if (options && typeof options == 'object') {
            if (options.expires) {
                sCookie += '; expires=' + options.expires.toGMTString();
            }
            if (options.path) {
                sCookie += '; path=' + options.path.toString();
            }
            if (options.domain) {
                sCookie += '; domain=' + options.domain.toString();
            }
            if (options.secure) {
                sCookie += '; secure';
            }
        }
        document.cookie = sCookie;
    }

    cookieRestore(sName) {
        var oCrumbles = document.cookie.split(';');
        for (var i = 0; i < oCrumbles.length; i++) {
            var oPair = oCrumbles[i].split('=');
            var sKey = decodeURIComponent(oPair[0].trim());
            var sValue = oPair.length > 1 ? oPair[1] : '';
            if (sKey === sName) {
                return decodeURIComponent(sValue);
            }
        }
        return '';
    }

    cookieRemove(sName, options) {
        if (!options) {
            options = {};
        }
        options.expires = new Date();
        this.cookieStore(sName, '', options);
    }

    store(key, data, place, options) {
        var self = this;
        if (!place) {
            place = self.config.storage;
        }
        switch (place) {
            case 'cookie':
                return this.cookieStore(key, data, options);
            case 'sessionStorage':
                return sessionStorage.setItem(key, JSON.stringify(data));
            case 'localStorage':
            default:
                return localStorage.setItem(key, JSON.stringify(data));
        }
    }

    restore(key, place) {
        var self = this;
        if (!place) {
            place = self.config.storage;
        }
        switch (place) {
            case 'cookie':
                return JSON.parse(this.cookieRestore(key));
            case 'sessionStorage':
                return JSON.parse(sessionStorage.getItem(key));
            case 'localStorage':
            default:
                return JSON.parse(localStorage.getItem(key));
        }
    }

    remove(key, place, options) {
        var self = this;
        if (!place) {
            place = self.config.storage;
        }
        switch (place) {
            case 'cookie':
                return this.cookieRemove(key, options);
            case 'sessionStorage':
                return sessionStorage.removeItem(key);
            case 'localStorage':
            default:
                return localStorage.removeItem(key);
        }
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
            return v.toString(16);
        });
    }

    isGuid(stringToTest) {
        if (stringToTest[0] === "{") {
            stringToTest = stringToTest.substring(1, stringToTest.length - 1);
        }
        var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}$/gi;
        return regexGuid.test(stringToTest);
    }

    toBinary(string) {
        // convert a Unicode string to a string in which
        // each 16-bit unit occupies only one byte
        const codeUnits = new Uint16Array(string.length);
        for (let i = 0; i < codeUnits.length; i++) {
            codeUnits[i] = string.charCodeAt(i);
        }
        return String.fromCharCode(...new Uint8Array(codeUnits.buffer));
    }

    fromBinary(binary) {
        const bytes = new Uint8Array(binary.length);
        for (let i = 0; i < bytes.length; i++) {
            bytes[i] = binary.charCodeAt(i);
        }
        return String.fromCharCode(...new Uint16Array(bytes.buffer));
    }

    beep(duration) {
        if (!this.audioCtx) {
            this.initAudio();
        }
        let oscillator = this.audioCtx.createOscillator();
        let gainNode = this.audioCtx.createGain();

        oscillator.connect(gainNode);
        gainNode.connect(this.audioCtx.destination);

        gainNode.gain.value = 1.0;
        oscillator.frequency.value = 675;
        oscillator.type = 'square';

        oscillator.start();

        setTimeout(
            function () {
                oscillator.stop();
            },
            duration
        );
    }

    abort() {
        this.xhrAbort = Date.now();
    }

    getEndpoint(endpoint) {
        return this.config.url + endpoint.replace(/^\/+/g, '');
    }

    request(endpoint, data, resolveFn, rejectFn, progressFn, method) {
        var self = this;
        var xhrCreated = Date.now();
        var xhr = new XMLHttpRequest();
        var url = "";

        var isAborted = function () {
            var test = this.xhrAbort > xhrCreated;
            return test;
        }.bind(this);

        if (typeof data == "undefined") {
            data = {};
        }

        if (typeof method == "undefined") {
            method = 'POST';
        }

        var rejected = false;

        var progress = function (data) {
            self.event('API_PROGRESS', data);
            if (typeof progressFn === "function") {
                if (!isAborted()) {
                    progressFn(data);
                }
            }
        }

        var resolve = function (data) {
            progress(100);
            if (typeof resolveFn === "function") {
                if (!isAborted()) {
                    resolveFn(data)
                }
            }
        }

        var reject = function (data) {
            if (rejected) {
                return;
            } else {
                rejected = true;
            }

            progress(100);

            if (self.isDef(data) && self.config.debug) {
                console.error(data);
            }

            self.event('API_STATUS', data.status);

            if (typeof rejectFn === "function") {
                if (!isAborted()) {
                    rejectFn(data);
                }
            }
        }

        progress(0);

        url = self.getEndpoint(endpoint);

        xhr.open(method, url, true);

        if (typeof data.files !== "undefined") {
            let formData = new FormData();
            for (var f = 0; f < data.files.length; f++) {
                formData.append('files[]', data.files[f], data.files[f].name);
            }
            delete data.files;
            formData.append('_input', JSON.stringify(data));
            data = formData;
        } else {
            xhr.setRequestHeader('Content-Type', 'application/json');
            data = JSON.stringify(data);
        }

        if (self.config.token) {
            xhr.setRequestHeader('Authorization', `${self.config.authorization} ${self.config.token}`);
        }

        xhr.setRequestHeader('Accept', 'application/json');

        xhr.onprogress = function (event) {
            if (event.lengthComputable) {
                var complete = (event.loaded / event.total * 100 | 0);
                progress(complete);
            }
        }

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                try {
                    var obj = JSON.parse(xhr.response);
                    if (obj.status.success) {
                        return resolve(obj.data);
                    } else {
                        return reject({
                            status: obj.status.code,
                            data: obj.data
                        });
                    }
                } catch (error) {
                    return reject({
                        status: xhr.status,
                        data: error
                    });
                }
            }
        };

        xhr.onerror = function () {
            return reject({
                status: xhr.status,
                statusText: xhr.statusText
            });
        };

        xhr.send(data);

    }

}

export default API;