import React from 'react'
import BaseComponent from '../../../../common/base';
import { Card, Button, Form, Row, Col } from 'react-bootstrap';
import QuestionnairePartialCard from '../../../partials/card';
import coverPhoto from '../../../../../assets/images/KP_0051_happiness.jpg'

export class StepEmotionsNamingHappinessSecond extends BaseComponent {

    onSubmit(e) {
        this.props.dbSet('facialExpressionRecognition.happiness02.reactionTime', this.firstReactionTime());
        this.props.dbSet('facialExpressionRecognition.happiness02.responseTime', this.sinceMount());
        this.props.dbSet('facialExpressionRecognition.happiness02.answer', this.state.answer);
        this.props.dbSet('facialExpressionRecognition.happiness02.isCorrect', 'happiness' === this.normalizeWord(this.state.answer));
        this.props.nextStep();
    }

    render() {
        return (
            <>
                <QuestionnairePartialCard
                    onSubmit={(e) => this.onSubmit(e)}
                    side={
                        <Card.Img variant="top" src={coverPhoto} />
                    }
                    imgMaxWidth={`50vh`}
                    title={this.t(`questionnaire.emotions.naming.title`)}
                    body={
                        <>
                            <Card.Text>
                            </Card.Text>

                            <Row>
                                <Col md={6}>
                                    {
                                        ['anger', 'surprise', 'happiness'].map(itm => {
                                            return (
                                                <Form.Group key={`radio-emotions-naming-${itm}`}>
                                                    <Form.Check
                                                        custom
                                                        required
                                                        type={'radio'}
                                                        name={`emotions-naming`}
                                                        value={itm}
                                                        id={`radio-emotions-naming-${itm}`}
                                                        label={this.t(`questionnaire.emotions.naming.option.${itm}`)}
                                                        checked={this.state.answer === itm}
                                                        onChange={(e) => this.onChange('answer', e)}
                                                    />
                                                </Form.Group>
                                            )
                                        })
                                    }
                                </Col>
                                <Col md={6}>
                                    {
                                        ['sadness', 'disgust', 'fear'].map(itm => {
                                            return (
                                                <Form.Group key={`radio-emotions-naming-${itm}`}>
                                                    <Form.Check
                                                        custom
                                                        required
                                                        type={'radio'}
                                                        name={`emotions-naming`}
                                                        value={itm}
                                                        id={`radio-emotions-naming-${itm}`}
                                                        label={this.t(`questionnaire.emotions.naming.option.${itm}`)}
                                                        checked={this.state.answer === itm}
                                                        onChange={(e) => this.onChange('answer', e)}
                                                    />
                                                </Form.Group>
                                            )
                                        })
                                    }
                                </Col>
                            </Row>

                            <Button type="submit" variant="primary" className="mt-3">
                                {this.t(`questionnaire.common.saveAndNextStep`)}
                            </Button>
                        </>
                    }
                />
            </>
        )
    }

}

export default StepEmotionsNamingHappinessSecond;