import React, { Component } from 'react'
import { Card, Row, Col, Form } from 'react-bootstrap';

export class QuestionnairePartialCard extends Component {

    onSubmit(e) {
        e.preventDefault();
        if (typeof this.props.onSubmit === "function") {
            this.props.onSubmit(e);
        }
    }

    render() {

        const innerLayout =
            (this.props.img || this.props.side)
                ?
                (
                    <>
                        <Col md={4} className="text-center p-3">
                            {
                                this.props.img && (<Card.Img variant="top" src={this.props.img} style={{ maxWidth: '25vh' }} />)
                            }
                            {
                                this.props.side
                            }
                        </Col>
                        <Col md={8}>
                            <Card.Title>
                                {this.props.title}
                            </Card.Title>
                            {this.props.body}
                        </Col>
                    </>
                )
                :
                (
                    <Col md={12}>
                        <Card.Title>
                            {this.props.title}
                        </Card.Title>
                        {this.props.body}
                    </Col>
                );

        const footer = (this.props.footer)
            ?
            (
                <Card.Footer>
                    {this.props.footer}
                </Card.Footer>
            )
            :
            (<></>);

        return (
            <Form onSubmit={(e) => this.onSubmit(e)} className="col d-flex p-0 justify-content-center align-items-center">
                <Card style={{ height: 'auto', width: '100%', maxWidth: this.props.maxWidth || '32rem' }}>
                    <Card.Body>
                        <Row className="justify-content-center align-items-center">
                            {innerLayout}
                        </Row>
                    </Card.Body>
                    {footer}
                </Card>
            </Form>
        )
    }

}

export default QuestionnairePartialCard;