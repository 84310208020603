import React from 'react'
import BaseComponent from '../../../common/base';
import PartialTrailEngine from './partials/engine';

export class StepTrailB extends BaseComponent {

    constructor(props) {
        super(props);
        this.buttons = [
            { id: this.t(`questionnaire.char.1`) },
            { id: this.t(`questionnaire.char.A`) },
            { id: this.t(`questionnaire.char.2`) },
            { id: this.t(`questionnaire.char.B`) },
            { id: this.t(`questionnaire.char.3`) },
            { id: this.t(`questionnaire.char.C`) },
            { id: this.t(`questionnaire.char.4`) },
            { id: this.t(`questionnaire.char.D`) },
            { id: this.t(`questionnaire.char.5`) },
            { id: this.t(`questionnaire.char.E`) },
            { id: this.t(`questionnaire.char.6`) },
            { id: this.t(`questionnaire.char.F`) },
            { id: this.t(`questionnaire.char.7`) },
            { id: this.t(`questionnaire.char.G`) },
            { id: this.t(`questionnaire.char.8`) },
            { id: this.t(`questionnaire.char.H`) },
            { id: this.t(`questionnaire.char.9`) },
            { id: this.t(`questionnaire.char.I`) },
            { id: this.t(`questionnaire.char.10`) },
            { id: this.t(`questionnaire.char.J`) },
        ]
    }

    render() {
        return (
            <>
                <PartialTrailEngine
                    ident="B"
                    buttons={this.buttons}
                    title={this.t(`questionnaire.trail.B.task.title`)}
                    {...this.props}
                />
            </>
        )
    }

}

export default StepTrailB;