import React from 'react';
import { Col, Container, Image, Jumbotron, Row } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import BaseComponent from '../common/base';
import Footer from './partials/footer';
import Navigation from './partials/navigation';
// import SubscribePatient from './partials/subscribe-form';
import preloadImages from "../common/preloadImages.js";
// import heroImage0 from '../../assets/images/hero0.jpg';
// import heroImage1 from '../../assets/images/hero1.jpg';
// import heroImage2 from '../../assets/images/hero2.jpg';
import heroImage3 from '../../assets/images/hero3.jpg';
// import brainIcon from '../../assets/images/brain3x.png';
import headIcon from '../../assets/images/head3x.png';

export class LandingPage extends BaseComponent {

    constructor(props) {
        super(props);

        this.heroImages = [heroImage3];
        this.heroInterval = null;

        this.state = Object.assign(
            this.state,
            {
                heroIndex: 0
            }
        );

        this.section_navbar = React.createRef();
        this.section_hero = React.createRef();
        this.section_01 = React.createRef();
        this.section_02 = React.createRef();
        this.section_03 = React.createRef();
        this.section_04 = React.createRef();
        this.section_cta = React.createRef();
        this.section_footer = React.createRef();

        this.section_to_hash = [
            { "section": this.section_navbar, "hash": 'navbar' },
            { "section": this.section_hero, "hash": 'hero' },
            { "section": this.section_01, "hash": '01' },
            { "section": this.section_02, "hash": '02' },
            { "section": this.section_03, "hash": '03' },
            { "section": this.section_04, "hash": '04' },
            // { "section": this.section_cta, "hash": 'cta' }
        ];

        this.handleCurrentHash = this.handleCurrentHash.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
        this.handleCurrentHash();
        window.addEventListener("hashchange", this.handleCurrentHash, false);
        preloadImages(this.heroImages);
        this.heroInterval = setInterval(
            (self) => {
                let nextIndex = self.state.heroIndex + 1;
                if (nextIndex >= this.heroImages.length) {
                    nextIndex = 0;
                }
                self.setState({ heroIndex: nextIndex });
            },
            7500,
            this
        );

    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("hashchange", this.handleCurrentHash, false);
    }

    handleCurrentHash() {
        this.scrollToSection(window.location.hash.replace('#', ''));
    }

    scrollToSection(sectionName) {
        let section = null;
        this.section_to_hash.forEach(item => {
            if (item.hash === sectionName) {
                section = item.section;
            }
        });
        if (section === null) {
            return;
        }
        setTimeout(
            () => {
                section.current.scrollIntoView(
                    {
                        behavior: 'smooth'
                    }
                );
            },
            150
        );
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{this.t('landing.helmet.title')}</title>
                    <meta name="description" content={this.t('landing.helmet.description')} />
                </Helmet>

                <Container className={`landing-page landing-page-${this.props.language}`}>

                    <header>

                        <Container>

                            <Row
                                id={this.t('landing.navbar.link.section_navbar.href')}
                                className={`content-section`}
                                ref={this.section_navbar}
                            >
                                <Col>
                                    <Navigation
                                        scrollToSection={(section) => this.scrollToSection(section)}
                                        {...this.props}
                                    />
                                </Col>
                            </Row>

                        </Container>

                    </header>

                    <Row
                        id={this.t('landing.navbar.link.section_hero.href')}
                        className={`content-section`}
                        ref={this.section_hero}
                        style={{ paddingTop: 130 }}
                    >
                        <Col>
                            <Jumbotron
                                className="hero"
                                style={{ backgroundImage: `url(${this.heroImages[this.state.heroIndex]})` }}
                            >
                                <Row>
                                    <Col xl={8} className="pt-3 pb-3 pr-5">
                                        <h1 className="pt-2 pb-2">
                                            {this.t('landing.section.hero.h1')}
                                        </h1>
                                        <p className="pt-2 pb-2 lead">
                                            {this.t('landing.section.hero.lead')}
                                        </p>
                                        <p className="pt-2">
                                            {/*
                                            <Button
                                                variant="purple"
                                                href={this.props.baseUrl + '#' + this.t('landing.navbar.link.section_04.href')}
                                            >
                                                {this.t('landing.section.hero.cta.text')}
                                            </Button>
                                            */}
                                        </p>
                                    </Col>
                                </Row>
                            </Jumbotron>
                        </Col>
                    </Row>

                    <Row
                        id={this.t('landing.navbar.link.section_01.href')}
                        className={`content-section`}
                        ref={this.section_01}
                    >
                        <Col md={12} lg={12}>
                            <h2 className="pt-2 pb-2">
                                {this.t('landing.section.01.h1')}
                            </h2>
                        </Col>

                        <Col md={12} lg={8} className="pt-4 pb-4">
                            <p>
                                {this.t('landing.section.01.p.06')}
                            </p>
                            <p>
                                {this.t('landing.section.01.p.07')}
                            </p>
                            <p>
                                {this.t('landing.section.01.p.08')}
                            </p>
                        </Col>
                        <Col md={12} lg={4} className="pt-4 pb-5 text-center h-100">
                            <Image src={headIcon} fluid />
                        </Col>

                    </Row>
                    {/* 
                    <>
                        <Row
                            id={this.t('landing.navbar.link.section_02.href')}
                            className={`content-section`}
                            ref={this.section_02}
                        >
                            <Col md={12} lg={12}>
                                <h2 className="pt-4 pb-4">
                                    {this.t('landing.section.02.h1')}
                                </h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} lg={4} className="mb-5">
                                <Card style={{ height: "100%" }}>
                                    <div className="custom-card-img p-5">
                                        <Card.Img variant="top" src={dartsIcon} />
                                    </div>
                                    <Card.Body>
                                        <Card.Title>
                                            {this.t('landing.section.02.card.03.title')}
                                        </Card.Title>
                                        <Card.Text>
                                            {this.t('landing.section.02.card.03.text')}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col md={12} lg={4} className="mb-5">
                                <Card style={{ height: "100%" }}>
                                    <div className="custom-card-img p-5">
                                        <Card.Img variant="top" src={compassIcon} />
                                    </div>
                                    <Card.Body>
                                        <Card.Title>
                                            {this.t('landing.section.02.card.01.title')}
                                        </Card.Title>
                                        <Card.Text>
                                            {this.t('landing.section.02.card.01.text')}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col md={12} lg={4} className="mb-5">
                                <Card style={{ height: "100%" }}>
                                    <div className="custom-card-img p-5">
                                        <Card.Img variant="top" src={ideasIcon} />
                                    </div>
                                    <Card.Body>
                                        <Card.Title>
                                            {this.t('landing.section.02.card.02.title')}
                                        </Card.Title>
                                        <Card.Text>
                                            {this.t('landing.section.02.card.02.text')}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </>
                    */}
                    <Row
                        id={this.t('landing.navbar.link.section_03.href')}
                        className={`content-section`}
                        ref={this.section_03}
                    >
                        <Col md={12} lg={12} className="pt-4">
                            <h2 className="pt-2 pb-2">
                                {this.t('landing.section.03.h1')}
                            </h2>
                        </Col>
                        <Col md={12} lg={12} className="pt-4">
                            <p>
                                {this.t('landing.section.03.p.01')}
                            </p>
                        </Col>
                    </Row>

                    <Row
                        id={this.t('landing.navbar.link.section_04.href')}
                        className={`content-section`}
                        ref={this.section_04}
                    >
                        <Col md={12} lg={12} className="pt-4">
                            <h2 className="pt-2 pb-2">
                                {this.t('landing.section.04.h1')}
                            </h2>
                        </Col>
                        <Col md={12} lg={6} className="pt-2 pb-2">
                            <p>
                                {this.t('landing.section.04.p.03')}
                            </p>
                            <p>
                                {this.t('landing.section.04.p.04')}
                                {` `}
                                {this.t('landing.section.04.p.05')}
                                {` `}
                                <a href={this.t('landing.section.04.link.href')}>
                                    {this.t('landing.section.04.link.text')}
                                </a>
                            </p>
                        </Col>
                        <Col md={12} lg={6} className="pt-2 pb-2">
                            <p style={{ wordBreak: "break-word" }}>
                                {this.t('landing.section.04.p.01.01')}
                                <br />{this.t('landing.section.04.p.01.02')}
                                <br />{this.t('landing.section.04.p.01.03')}
                                <br />{this.t('landing.section.04.p.01.04')}
                            </p>
                            <p style={{ wordBreak: "break-word" }}>
                                {this.t('landing.section.04.p.02.01')}
                                <br />{this.t('landing.section.04.p.02.03')}
                                <br />{this.t('landing.section.04.p.02.04')}
                            </p>
                        </Col>
                    </Row>

                    {
                        /*
                            <Row
                                id={this.t('landing.navbar.link.section_cta.href')}
                                className={`content-section`}
                                ref={this.section_cta}
                            >
                                <Col>
                                    <Jumbotron className="form mt-4 mb-2 pt-5 pb-5 jumbotron-form">
                                        <Row>
                                            <Col xl={12} className="pt-1 pb-3">
                                                <h2 className="pb-2">
                                                    {this.t('landing.section.cta.h1')}
                                                </h2>
                                                <div className="pt-2 pb-2">
                                                    <ListGroup variant="flush">
                                                        <ListGroup.Item>{this.t('landing.section.cta.li.01')}</ListGroup.Item>
                                                        <ListGroup.Item>{this.t('landing.section.cta.li.02')}</ListGroup.Item>
                                                        <ListGroup.Item>{this.t('landing.section.cta.li.03')}</ListGroup.Item>
                                                        <ListGroup.Item>{this.t('landing.section.cta.li.04')}</ListGroup.Item>
                                                        <ListGroup.Item>{this.t('landing.section.cta.li.05')}</ListGroup.Item>
                                                        <ListGroup.Item>{this.t('landing.section.cta.li.06')}</ListGroup.Item>
                                                        <ListGroup.Item>{this.t('landing.section.cta.li.07')}</ListGroup.Item>
                                                        <ListGroup.Item>{this.t('landing.section.cta.li.08')}</ListGroup.Item>
                                                    </ListGroup>
                                                </div>
                                            </Col>
                                            <Col xl={12}>
                                                <SubscribePatient {...this.props} />
                                            </Col>
                                        </Row>
                                    </Jumbotron>
                                </Col>
                            </Row>
                        */
                    }

                    <footer>
                        <Row
                            id={this.t('landing.navbar.link.section_footer.href')}
                            className={`content-section content-footer`}
                            ref={this.section_footer}
                        >
                            <Col>
                                <Footer {...this.props} />
                            </Col>
                        </Row>
                    </footer>

                </Container>
            </>
        )
    }
}

export default LandingPage
